import { createStore } from "vuex";

export default createStore({

  state: {
    user: { },
    accessToken: '',
    JWTToken: '',
    loginAPIUser: { },
    stUserData: {
      title: 'Herr',
      birthTitle: 'Herr',
      firstname: '',
      lastname: '',
      street: '',
      streetnumber: '',
      zipcode: '',
      city: '', 
      country: 'DE',
      phone: '',
      phone_prefix: '+49',
      mobile: '',
      mobile_prefix: '+49',
      email: '',
      primary_address_id: 0,
      travelers: Array()
    },
    stBookingData: {
      uniqueTravelId: null, 
      oid: null, 
      baid: null, 
      chooseCabin: null,
      pmc: null, 
      cabin: null, 
      cabinType: null, 
      hasFlight: null, 
      startAirport: null, 
      totalPrice: null
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getJWTToken(state) {
      return state.JWTToken;
    },
    getLoginAPIUser(state) {
      return state.loginAPIUser;
    },
    getUserData(state) {
      return state.userData;
    },
    getBookingData(state) {
      return state.bookingData;
    }
  },
  mutations: {
    setUser(state,value) {
      state.user = value;
    },
    clearUser(state) {
      state.user = {};
    },
    setAccessToken(state,value) {
      state.accessToken = value;
    },
    setJWTToken(state,value) {
      state.JWTToken = value;
    },    
    setLoginAPIUser(state,value) {
      state.loginAPIUser = value;
      console.log('VUEX store: loginAPIUser', state.loginAPIUser);
    },
    setUserData(state, value) {
      console.log(state, value);
      if(typeof value.field !== 'undefined') state.userData[value.field] = value.value;
      else state.userData = value.value;
      console.log('VUEX store: Seereisen.de userData',state.userData);
    },
    setBookingData(state, value) {
      state.bookingData = value;
      console.log('VUEX store: Seereisen.de bookingData',state.bookingData);
    }
  },
  actions: {
    //async functions
    // incrementAsync ({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       commit('increment')
    //       resolve()
    //     }, 1000)
    //   })
    // }
  },
  modules: {}
});

