import { createApp, ref } from 'vue';
import App from './App.vue';
import router from './router';
import useJWTToken from './composables/useJWTAuth_v2';
import { projectAuth } from './config/firebase';
import useFirebaseLogon from './composables/useFirebaseLogon';
import store from './store/store';
import { mapMutationsMainJs } from './composables/useVuexStore';

import './assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';



var app = null;
var user = null;
var JWTToken = null; 
var AccessToken = null;

const { anonymousLogin } = useFirebaseLogon();

const { setUser, setAccessToken, setJWTToken } = mapMutationsMainJs(['setUser', 'setAccessToken', 'setJWTToken'], store);

projectAuth.onAuthStateChanged(async (firebaseUser) => {  //App will be created when Firebase User-Status is received
  if(firebaseUser) {
    user = firebaseUser;

    let plainUserObject = JSON.parse(JSON.stringify(firebaseUser));
    AccessToken = plainUserObject.stsTokenManager.accessToken; //Google Firebase Access Token
    user.accessToken = AccessToken;
    if(user.isAnonymous) user.displayNameGuest = 'Gast';
  } else {
    let response = anonymousLogin();
  }

  if(!app) {
    fetchJWTToken(JWTToken)
      .then((JWTToken) => {
        app = createApp(App, {});
        user.test456='test456';
        app
          .use(router)
          .use(store)
          .provide('user', user)
          .provide('JWTToken', JWTToken)  //Seereisen API Access Token
          .provide('AccessToken', AccessToken) //Google Firebase Access Token for logged on users
          .mount('#app');
        console.log('APP MOUNTED');
        constantlyFetchNewToken(JWTToken);
        setJWTToken(JWTToken);
      });
  } else {
    user.test123='test123';
    app.provide('user', user);
    app.provide('JWTToken', JWTToken);
    app.provide('AccessToken', AccessToken);
  }
  
  console.log('AUTH-STATE CHANGED -> USER:', user);

  setUser(user);  //save whole LoginAPI User data to vuex store  
  setAccessToken(AccessToken);  
});

function constantlyFetchNewToken(JWTToken) {
  // console.log('constantlyFetchNewToken', JWTToken);
  setTimeout(() => {
    fetchJWTToken(JWTToken);
    constantlyFetchNewToken(JWTToken);
  }, 300000); //Every 5 minutes
}

async function fetchJWTToken(JWTToken) {
  // console.log('fetchJWTToken', JWTToken);
  const { initializeJWTToken } = useJWTToken();

  await initializeJWTToken(JWTToken)
  .then((result) => { 
    let tokenIsValid = false;
    JWTToken = result; 

    if(JWTToken !== null) tokenIsValid = true;

    if(!tokenIsValid) { 
      throw new Error('Token invalid!');
    } else {
      if(app) {
        // console.log('App already initialized, updating Token:', JWTToken);
        app.provide('JWTToken', JWTToken);
      }
    } 
  })
  .catch((err) => {
    console.log(err);
  });
  return JWTToken;
}