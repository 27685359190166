<template>
  <div class="container">  
    <div class="backdrop" v-if="showBackdrop"></div>
    <div class="ccontainer">
      <div class="error" v-if="error!=''">{{ error }}</div>
      <h2>Reisevergleich ({{cruisesDetails.length}} Reisen)</h2>
      <p class="introtext">
        Nachfolgend sehen Sie alle ausgewählten Reisen im direkten Vergleich.
      </p>

      <div class="compare-header d-flex justify-content-between flex-nowrap">
        <template v-for="(cruise,index) in cruisesDetails" :key="index">
          <CompareHeader :cruise="cruise" />
        </template>
      </div>
      <div class="compare-buttons d-flex justify-content-center bg-light py-3">
        <button class="btn btn-outline-primary mx-1" :class="{'btn-primary' : showCategory=='details'}" @click.prevent="toggleCategory('details')">Details</button>
        <button class="btn btn-outline-primary mx-1" :class="{'btn-primary' : showCategory=='routes'}" @click.prevent="toggleCategory('routes')">Route</button>
        <button class="btn btn-outline-primary mx-1" :class="{'btn-primary' : showCategory=='alternatives'}" @click.prevent="toggleCategory('alternatives')">Alternativtermine</button>
        <button class="btn btn-outline-primary mx-1" :class="{'btn-primary' : showCategory=='companies'}" @click.prevent="toggleCategory('companies')">Veranstalter</button>
      </div>
      <div class="compare-body d-flex justify-content-between flex-nowrap">
        <template v-for="(cruise,index) in cruisesDetails" :key="index">
          <CompareBody :cruise="cruise" :showCategory="showCategory" />
        </template>
      </div>        
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { inject, onBeforeMount, onMounted, registerRuntimeCompiler } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
//Components
import CompareHeader from '../components/Compare/CompareHeader.vue';
import CompareBody from '../components/Compare/CompareBody.vue';
//Composables
import useBookingAPI from '../composables/useBookingAPI';
import useMetaAPI from '../composables/useMetaAPI';
import helperFunctions from '../composables/helperFunctions';

export default {
  props: [],
  components: {CompareHeader, CompareBody},
  setup(props) {
    const JWTToken = ref(inject('JWTToken'));
    const lang = inject('lang');

    const error = ref('');
    const route = useRoute();
    const cruises = ref([]);
    const cruisesDetails = ref([]);
    
    const allRegions = ref({});
    let cruiseDetails = null;

    const { BookingApiError, getCruiseDetails } = useBookingAPI();
    const { MetaApiError, getRegions } = useMetaAPI();
    const { convertYMDHISToDate, generateSlug } = helperFunctions();

    const showBackdrop = ref(false);
    const blockContent = (status) => {
      showBackdrop.value = status;
    }   

    onBeforeMount(() => {
      if(route.query.id.length >= 1 && route.query.id.length <= 11) {
        cruises.value = route.query.id;
      } else {
        router.push({name: "Home"});
      }
    });

    

    onMounted(() => {
      //fetch all cruises details 
      blockContent(false);

      
      getRegions("", JWTToken.value)
      .then((regionsResult) => {
        allRegions.value = regionsResult.regions;
        console.log(allRegions.value);
        fetchAllCruiseDetails();
      })
      .catch((err) => { console.log(err), error.value += MetaApiError });
      
      
      blockContent(false);
    });

    const fetchAllCruiseDetails = () => {
      cruises.value.forEach((uniqueTravelId) => {
        cruiseDetails = null;
        if(JWTToken.value !== null && typeof JWTToken.value !== 'undefined') {
          getCruiseDetails(uniqueTravelId, JWTToken.value)
            .then((result) => {
              if(!result) {
                //Cruise coundn't be fetched
                
                if(BookingApiError.value.code==200 && BookingApiError.value.error=='no_data_available') {} //Cruise doesn't exist (any more)
                error.value += BookingApiError.value.userMessage;
                
              } else {
                //Fetched Results from Booking-API
                console.log(result);
                cruiseDetails = result.cruiseDetails; 
                cruiseDetails.uniqueTravelId = uniqueTravelId;

                if(result.alternativeDepartures) cruiseDetails.alternateDepartures = result.alternativeDepartures;

                //General cruise details
                cruiseDetails.destinations = [];
                Object.keys(allRegions.value).forEach((key) => {
                  if(cruiseDetails.routeRegions.includes(allRegions.value[key].regionId.toString())) {
                    
                    cruiseDetails.destinations.push(allRegions.value[key]) //Pushes all route destinations with RouteId and RouteName to array
                  }
                })

                //Formatted Cruise Start and End Dates
                let dateFormatOptions = {weekday:'short', year: 'numeric', month: 'numeric', day: 'numeric'};
                cruiseDetails.routeScheduleStartFormatted = convertYMDHISToDate(cruiseDetails.routeScheduleStart).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);
                cruiseDetails.routeScheduleEndFormatted = convertYMDHISToDate(cruiseDetails.routeScheduleEnd).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);

                //Fetch Dates and cruiseId of all Depatures
                if(cruiseDetails.alternateDepartures.length>0) {
                  cruiseDetails.alternateDepartures.forEach((key, index) => {
                    cruiseDetails.alternateDepartures[index].routeScheduleStartDate = convertYMDHISToDate(cruiseDetails.alternateDepartures[index].routeScheduleStart); //Deutsches Datumsformat ergänzen
                    cruiseDetails.alternateDepartures[index].routeScheduleStartMonth = lang.monthNamesShort[cruiseDetails.alternateDepartures[index].routeScheduleStartDate.getMonth()];

                    //TEMPORÄRE LÖSUNG
                    if(cruiseDetails.alternateDepartures[index].uniqueTravelId == uniqueTravelId) {
                      cruiseDetails.cruiseId = cruiseDetails.alternateDepartures[index].cruiseId;  //TEMPORÄRE LÖSUNG!! Die cruiseId wird NOCH für die Vacancy-Prüfung benötigt
                    }
                    //TEMPORÄRE LÖSUNG ENDE  
                  });
                } else {
                  console.log('Konnte keine Departures für diese Route ermitteln!');
                }

                if(result.cruiseOnlyPrices) cruiseDetails.cruiseOnlyPrices = result.cruiseOnlyPrices;
                
                if(result.cruiseWithFlight) cruiseDetails.flightPrices = result.cruiseWithFlight.flightPrices;  
                

                //Cruise Title Image
                cruiseDetails.titleimageSrc = 'https://media.xmlteam.de/files/aida-cruises/aidabella/schiff/4.jpeg';
                
                cruisesDetails.value.push(cruiseDetails)

              }            
            })
            .catch((err) => { 
              console.log(err), error.value += BookingApiError.value.message;
            });
        }
      });
    }

    const showCategory = ref('details');

    const toggleCategory = (cat) => {
      showCategory.value = cat;
    };

    return { 
      showBackdrop, blockContent,
      cruisesDetails, showCategory, toggleCategory 

      }
  }
}
</script>

<style>

</style>