<template>
  <div class="dashboard-userdata">
    <h3>Ihre Reisen</h3>
    <p class="introtext">
      In diesem Bereich sehen Sie alle gebuchten Reisen in der Übersicht und können sie jederzeit verwalten.
    </p>
    <div class="error" v-if="error!=''">{{ error }}</div>

    <div class="bookings-cruise">
      <transition appear>
      <div v-if="JWTToken && bookings.length" class="">
        <template v-for="(booking, index) in bookings" :key="index">
          <BookingSummary class="mb-3"
            :booking="booking"
            @error="handleBookingtError"
            />
        </template>
      </div>
      <div v-else>
        <p>
          Leider haben Sie bei uns noch keine Reise gebucht.<br>
          Suchen Sie doch eine traumhafte Reise aus unseren Angeboten aus und buchen Sie sie.
        </p>
      </div>
      </transition>
    </div>    
  </div>
</template>

<script>
//Modules
import { useRouter } from 'vue-router';
import { inject, onMounted, onUpdated, ref } from '@vue/runtime-core';
//Composables
import useLoginAPI from '../../composables/useLoginAPI';
import helperFunctions from '../../composables/helperFunctions';
//Components
import BookingSummary from '../Summaries/BookingSummary.vue';
import Spinner from '../Spinner.vue';


export default {
  components: {Spinner, BookingSummary},
  setup(props, context) {
    const JWTToken = ref(inject('JWTToken'));
    const user = inject('user');
    const AccessToken = inject('AccessToken');
    const error = ref('');
    const router = useRouter();   

    const {  } = helperFunctions();
    const { getBookings } = useLoginAPI();

    const bookings = ref([]);

    onMounted(() => {
      if(user) {
        getBookings(AccessToken, '')
          .then((result) => {
            bookings.value = result.bookings;
            bookings.value = bookings.value.reverse();
          })
          .catch((err) => {

          });
      }
    });  
    
    const handleBookingtError = (err) => {
      error.value = err;
    };

    return { error, JWTToken, bookings, handleBookingtError }
  }
}
</script>

<style>

</style>