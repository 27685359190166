<template>
  <div class="container">
    <div class="ccontainer col-8">
      <div v-if="!mailResent">
        <h2>Email-Adresse bestätigen</h2>
        <p class="mt-2">Bei Ihrer Registrierung haben wir Ihnen einen Bestätigungs-Link an Ihre E-Mail Adresse gesendet.</p>
        <p>Bitte folgen Sie dem Link in der E-Mail, um Ihre Registrierung abzuschließen.</p>
        <p>Sollte die E-Mail nicht angekommen sein, überprüfen Sie bitte auch Ihren Spam-Ordner. Zusätzlich können Sie den Bestätigungs-Link jederzeit <span class="link link-primary" @click="resendVerificationMail">erneut anfordern</span>.</p>
      </div>
      <div v-else>
        <h2>Email-Adresse bestätigen</h2>
        <template v-if="mailResentSuccess">
          <p>Wir haben Ihnen die E-Mail mit dem Bestätigungs-Link zu Ihrer Anmeldung erneut zugesendet.</p>
          <p>Bitte schauen Sie gegebenenfalls auch im Spam-Ordner Ihres E-Mail Postfachs nach.</p>
        </template>
        <template v-else>
          <p>Wir konnten Ihnen leider keine erneute E-Mail mit Bestätigungs-Link zusenden.</p>
          <p>Das System meldet: {{verificationResponse[1]}}</p>
          <p>Sollte dieser Fehler erneut auftreten, wenden Sie sich bitte gerne per E-Mail an unseren Kunden-Support: support@seereisen.de</p>
          <p>Wir entschuldigen uns für die Unnannehmlichkeiten.</p>
        </template>
      </div>       
    </div>   
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import useFirebaseLogon from '../composables/useFirebaseLogon';
import { inject, onBeforeMount } from '@vue/runtime-core';
//Components


export default {
  components: {  },
  setup() {
    
    const router = useRouter();
    const mailResent = ref(false);
    const mailResentSuccess = ref(false);

    const user = ref(inject('user'));

    const { verificationResponse, sendEmailVerificationMail } = useFirebaseLogon();

    const resendVerificationMail = async () => {
      await sendEmailVerificationMail(user.value)
        .then(result => {
          mailResentSuccess.value = result;
          mailResent.value = true;
        })
        .catch((err) => {
          console.log(err, verificationResponse.value);
        });
    };

    onBeforeMount(() => {
      if(!user.value) router.push({ name: 'Login' });
    });

    return { resendVerificationMail, mailResent, mailResentSuccess, verificationResponse };
  }
}
</script>

<style>

</style>