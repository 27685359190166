<template>
  <div class="dashboard-cabinfinder">
    <h3>Kabinenfinder</h3>
    <p class="introtext">
      In diesem Bereich können Sie eine Suche nach einer bestimmten Kabine auf einem bestimmten Schiff speichern. 
      Sobald diese Kabine dann buchbar ist, erhalten Sie eine Benachrichtigung.
    </p>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>