import { ref } from "vue";
import axios from "axios";
import helperFunctions from "./helperFunctions";


const MetaApiError = ref(null);
const { getSearchCriteria } = helperFunctions()

const metaAPIUrl = process.env.VUE_APP_SEEREISEN_DE_META_API;

/**
 * Fetches and returns Region information from Meta-API for all routes or for a single route, defined by passed regionId
 * @param {String} regionId -> numeric Region-ID to fetch single region information or empty string to fetch all regions
 * @param {String} token -> valid JWTToken
 * @returns {Object} Object with all or single region information
 */
const getRegions = async (regionId, token) => {
  MetaApiError.value = null;
  
  let endpoint = 'regions';
  if(regionId != '') endpoint = endpoint.concat('/').concat(regionId);  //regions/123
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  try {
    const response = await axios.get(metaAPIUrl.concat(endpoint), apiRequestConfig);
    //console.log('API Search request:', metaAPIUrl.concat(endpoint));
    //console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseMessage = response.statusText;
    const responseHeaders = response.headers;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Regions-Details (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    MetaApiError.value = err.message;
    console.log(MetaApiError.value);
  }
  return false;
}

/**
 * Fetches and returns all Countries
 * @param {String} token -> valid JWTToken
 * @returns {Object} Object with all countries
 */
 const getCountries = async (token) => {
  MetaApiError.value = null;
  
  let endpoint = 'countries';

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  try {
    const response = await axios.get(metaAPIUrl.concat(endpoint), apiRequestConfig);
    //console.log('API Search request:', metaAPIUrl.concat(endpoint));
    //console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseMessage = response.statusText;
    const responseHeaders = response.headers;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Countries (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    MetaApiError.value = err.message;
    console.log(MetaApiError.value);
  }
  return false;
}

/**
 * Fetches and returns detailed route information from Meta-API for a route identified by TCC's uniqueTravelId
 * @param {String} uniqueTravelId -> A cruise's unique TCC Travel-ID
 * @param {String} token -> valid JWTToken 
 * @returns {Object} Object with route data
 */
const getRoute = async (uniqueTravelId, token) => {
  MetaApiError.value = null;
  
  let endpoint = 'routes';
  if(uniqueTravelId != '' && token !== null) {
    endpoint = endpoint.concat('/').concat(uniqueTravelId);  //routes/DV-220205-2-14-42-7
  } else {
    MetaApiError.value = err.message;
    throw new Error('No Travel-ID for route details provided or token invalid.');
  }

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }
  try {
    const response = await axios.get(metaAPIUrl.concat(endpoint), apiRequestConfig);
    //console.log('API Search request:', metaAPIUrl.concat(endpoint));
    //console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseMessage = response.statusText;
    const responseHeaders = response.headers;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Routen-Details (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    MetaApiError.value = err.message;
    console.log(MetaApiError.value);
  }
  return false;  
}

/**
 * Fetches all available filter options for cruises
 * @param {String} token -> valid JWTToken 
 * @returns {Object} Obbject with all available filter categories and their possible values
 */
const getAllFilters = async (token) => {
  let endpoint = 'filters';
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }  
  try{
    const response = await axios.get(metaAPIUrl.concat(endpoint), apiRequestConfig);
    //console.log(response)

    const responseData = response.data;
    const responseStatus = response.status;
    const responseMessage = response.statusText;
    const responseHeaders = response.headers;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      let filters = {
        filterCategoriesCount: responseData.data.filterCategoriesCount,
        filterCategories: responseData.data.filterCategories
      }
      return filters;
    } else {
      throw new Error('Fehler beim Abruf der Filter (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    //console.log(err);
    MetaApiError.value = 'Filter konnten nicht abgerufen werden (Error: '+err.message+')';
    console.log(MetaApiError.value);
  }
  return false;
}

const useMetaAPI = () => {
  return  { MetaApiError, getRegions, getCountries, getRoute, getAllFilters };
}

export default useMetaAPI;