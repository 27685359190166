<template>
    <div class="container">        
        <div class="backdrop" v-if="showBackdrop"></div>
        <div class="ccontainer">
          <SearchForm @blockingOn="blockContent(true)" @blockingOff="blockContent(false)" />
        </div>       
    </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity'
//Components
import SearchForm from '../components/Search/SearchForm.vue'
//Composables

export default {
    components: { SearchForm },

    setup() {
      const showBackdrop = ref(false);
      const blockContent = (status) => {  //triggered by child component(s) to shade the background
        showBackdrop.value = status;
      }
      
      return { showBackdrop, blockContent }
    },

    
}
</script>

<style>
  
</style>