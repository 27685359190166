<template>
  <div class="compare-body-cruise border p-2 w-100">
<!-- <pre>{{cruise}}</pre> -->
    <div v-if="showCategory=='details'">
      <h4>Reise-Details</h4>
      <h5>Reise-Preise ohne Flug</h5>
      <template v-for="cop in cruise.cruiseOnlyPrices.prices" :key="cop.cruiseId" >
        <div v-if="cop.cruiseId == cruise.cruiseId">
            <span class="fw-bold" v-if="cop.cabinType=='1'">Innenkabine</span>
            <span class="fw-bold" v-if="cop.cabinType=='2'">Außenkabine</span>
            <span class="fw-bold" v-if="cop.cabinType=='3'">Balkonkabine</span>
            <span class="fw-bold" v-if="cop.cabinType=='4'">Suite</span>
            <span class="fw-bold">&nbsp;{{cop.cabin}}</span>
            <br>

            Reisestart ab: {{cop.startDestination}}<br>
            Reiseziel: {{cop.arrivalDestination}}<br>
            Minimalbelegung: {{cop.minOccupancy}}<br>
            Maximalbelegung: {{cop.maxOccupancy}}<br>
            Preis pro Erwachsener: {{cop.priceCruise}}<br>
            Erwachsener als 3./4. Person: {{cop.priceCruise34}}<br>
            Preis Single-Erwachsener: {{cop.priceCruiseSingle}}<br>
            Preis pro Kind: {{cop.priceCruiseChild}}<br>
            Kind als 3./4. Person: {{cop.priceCruiseChild34}}<br>
            <hr>
        </div>
      </template>

      <h5>Flugpreise</h5>
      <template v-for="flp in cruise.flightPrices" :key="flp.ccFlightCode" >
        <div>
            <span class="fw-bold">{{flp.ccCode}} Flug ab {{flp.ccStartAirport}}</span><br>
            Klasse: {{flp.ccClass}}<br>
            Hinreise<br>
            Start-Airport: {{flp.ccStartAirport}}<br>
            Ziel-Airport: {{flp.ccDestinationAirport}}<br>
            Rückreise<br>
            Start-Airport: {{flp.ccReturnStartAirport}}<br>
            Ziel-Airport: {{flp.ccReturnDestinationAirport}}<br>
            Preis pro Erwachsener: {{flp.ccPriceAdult}}<br>
            Preis pro Kind: {{flp.ccPriceChild}}
            
            <hr>
        </div>
      </template>       
    </div>

    <div v-if="showCategory=='routes'">
      <h4>Reise-Route</h4>
      <RouteDetails :uniqueTravelId="cruise.uniqueTravelId" />
    </div>   

    <div v-if="showCategory=='alternatives'">
      <h4>Alternativ-Termine</h4>
      <div class="d-flex justify-content-center flex-wrap">
        <div v-for="departure in cruise.alternateDepartures" :key="departure.uniqueTravelId" >
          <div class="calendar-btn">
            <div class="month">{{departure.routeScheduleStartMonth}}</div>
            <div class="day">{{departure.routeScheduleStartDate.getDate()}}</div>
            <div class="year">{{departure.routeScheduleStartDate.getFullYear()}}</div>
          </div> 
        </div>
      </div>      
    </div>

    <div v-if="showCategory=='companies'">
      <h4>Reise-Veranstalter</h4>
      <p>Kann bisher aus API noch nicht abgerufen werden.</p>
    </div>
    
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import RouteDetails from '../CruiseDetails/RouteDetails.vue'
import { onUpdated } from '@vue/runtime-core';

export default {
  components: {RouteDetails},
  props: ['cruise','showCategory'],
  setup(props) {

    const cruise = ref(null);
    const showCategory = ref(props.showCategory);

    if(typeof props.cruise !== 'undefined') {
      cruise.value = props.cruise;
    }

    onUpdated(() => {
      showCategory.value = props.showCategory;
    })

    return { cruise, showCategory }
  }
}
</script>

<style>

</style>