<template>
  <div class="container">
    <div class="ccontainer">
      <router-link :to="{ name: 'Imprint' }">Imprint</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
  max-width:1200px;
}
</style>