import { applyFilters } from "./cruiseListFilter";

/*
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
    @sortCruiseList
    * Sort given cruiseList by filter and direction given as Params.
    ! If no filter or direction are given, will sort by Date and in ASC direction.
    ? Possibly more sorting filters to be added later?
    ? Possibly increased tempo of Price sorting required?
    @Params
        {Object, Array}	cruiseList
        |> List of searchresults
        {Object, Array} filterList
        |> Optional parameter: Object of key/value pairs that shall be used as filter criteria {ship_brauhaus: 1, region_climate: 'warm', ...}
        {String}	    sortBy
        |> Optional Parameter: Value to be sorted by.
        {String} 	    direction
        |> Optional Parameter: Direction in which the values will be sorted.
    @Returns
        {Object}
        |> Sorted list of searchresults
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
*/
export function sortCruiseList(cruiseList, sortBy = 'date', direction = 'asc', filterList = null) {
    if(cruiseList === 'undefined' || cruiseList === null) {
        return false;
    } else {
        if(sortBy === 'undefined' || sortBy === null) {
            sortBy = 'date';
        }
        if(direction === 'undefined' || direction === null) {
            direction = 'asc';
        }
        let cl;
        let sortable = [];
        let sortedList = {
            totalCruisesCount: cruiseList.totalCruisesCount,
            cruises: {},
        };
        if(Array.isArray(cruiseList)) {
            cl = cruiseList;
        } else if(typeof cruiseList === 'object') {
            cl = Object.values(cruiseList.cruises);
        } else {
            throw new Exception('cruiseList is of incorrect Type');
        }
        cl.forEach((cruise) => {
            sortable.push(cruise);
        });

        sortSettingsStorage(sortBy, direction);
    
        switch(sortBy) {
            case 'date':
                if(direction === 'desc') { 
                    sortable.sort(
                        (a, b) => (Date.parse(Object.values(a.departures)[0].routeScheduleStart) < Date.parse(Object.values(b.departures)[0].routeScheduleStart)) ? 1 
                                : (Date.parse(Object.values(b.departures)[0].routeScheduleStart) < Date.parse(Object.values(a.departures)[0].routeScheduleStart)) ? -1 
                                : 0
                    );
                } else {
                    sortable.sort(
                        (a, b) => (Date.parse(Object.values(a.departures)[0].routeScheduleStart) > Date.parse(Object.values(b.departures)[0].routeScheduleStart)) ? 1 
                                : (Date.parse(Object.values(b.departures)[0].routeScheduleStart) > Date.parse(Object.values(a.departures)[0].routeScheduleStart)) ? -1 
                                : 0
                    );
                }
                break;
            case 'name':
                if(direction === 'desc') {
                    sortable.sort(
                        (a, b) => (a.routeName.toLowerCase() < b.routeName.toLowerCase()) ? 1 
                                : (b.routeName.toLowerCase() < a.routeName.toLowerCase()) ? -1
                                : 0
                    );
                } else {
                    sortable.sort(
                        (a, b) => (a.routeName.toLowerCase() > b.routeName.toLowerCase()) ? 1 
                                : (b.routeName.toLowerCase() > a.routeName.toLowerCase()) ? -1 
                                : 0
                    );
                }
                break; 
            case 'price':
                sortable.sort(
                    (a, b) => {
                        let pricesA = [];
                        Object.values(a.departures).forEach((departure) => {
                            pricesA.push(departure.cheapestPrice);
                        });
                        let cheapA = Math.min(...pricesA);
    
                        let pricesB = [];
                        Object.values(b.departures).forEach((departure) => {
                            pricesB.push(departure.cheapestPrice);
                        });
                        let cheapB = Math.min(...pricesB);
    
                        if(direction === 'desc') {
                            if(cheapA < cheapB) {
                                return 1;
                            } else if(cheapB < cheapA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            if(cheapA > cheapB) {
                                return 1;
                            } else if(cheapB > cheapA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                );
                break;
        }
 
        if((typeof filterList !== 'undefined') && (filterList !== null)) {
            sortable = applyFilters(sortable, filterList);
            return sortable;
        } else {
            sortable.forEach((cruise, index) => {
                sortedList.cruises[index] = cruise;
            });
            return sortedList;
        }
    }
}

export function sortSettingsStorage(sortBy = null, sortDir = null) {
    if(sortBy === null && sortDir === null) {
        return { 
            sortBy: ((typeof sessionStorage.getItem('sortBy') !== 'undefined') && (sessionStorage.getItem('sortBy') !== null)) ? sessionStorage.getItem('sortBy') : 'date',
            sortDir: ((typeof sessionStorage.getItem('sortDir') !== 'undefined') && (sessionStorage.getItem('sortDir') !== null)) ? sessionStorage.getItem('sortDir') : 'asc'
        };
    } else {
        if(sortBy !== null && sortBy !== 'undefined') {
            sessionStorage.setItem('sortBy', sortBy);
        }

        if(sortDir !== null && sortDir !== 'undefined') {
            sessionStorage.setItem('sortDir', sortDir);
        }

        if((sortBy !== null && sortBy !== 'undefined') && (sortDir !== null && sortDir !== 'undefined')) { 
            return true;
        } else {
            return false;
        }
    }
}