const parseAPIErrorCodes = (err, endpoint='') => {
  let errorMessage = '';
  if(err.search('429') > -1 && endpoint == '') errorMessage = 'Es wurden zu viele Anfragen in kurzer Zeit gesendet. Möglicherweise wurde dadurch nicht der gesamte Inhalt der Seite gelasen. Bitte warten Sie einen Moment und laden Sie die Seite dann erneut.';
  
  //Login API
  if(err.search('401') > -1) errorMessage = 'Ihr Sicherheitstoken konnte nicht verifiziert werden. Bitt loggen Sie sich erneut in die Seite ein und versuchen Sie es noch einmal.';
  if(err.search('404') > -1 && endpoint == 'addresses') errorMessage = 'Es konnte keine Adresse ermittelt werden.';
  return (errorMessage !== '') ? errorMessage : err;
};



const errorHandling = () => {
  return  { 
    parseAPIErrorCodes
  };
}

export default errorHandling;