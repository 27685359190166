<template>
  <div>
    <DashboardNavigation />
    <div class="container">  
      <div class="ccontainer">
        <div class="error" v-if="error!=''">{{ error }}</div>
        <h2>Herzlich Willkommen, {{(user.isAnonymous) ? 'lieber ' + user.displayNameGuest : user.displayName}}!</h2>
        <template v-if="subpage == null">
          <template v-if="user.isAnonymous">
            <p>
              In Ihrem <i>Mein Seereisen</i>-Bereich können Sie sich temporär einen Merkzettel erstellen, auf dem Sie zur Dauer Ihres Besuches
              interessante Reiseangebote speichern und diese miteinander vergleichen können.<br><br>
              Beachten Sie jedoch, dass auf dem Merkzettel gespeicherte Reisen wieder gelöscht werden, wenn Sie die Seite verlassen!<br><br>
              Um Reisen dauerhaft auf dem Merkzettel speichern und weitere, spannende Funktionen wie z.B. den Kabinen- und Preisfinder und weitere Vorteile in Anspruch zu nehmen,
              registrieren Sie sich einfach kostenlos und unverbindlich.<br><br>
              <router-link :to="{ name: 'Login', params: {'showLogin':false} }"><span class="btn btn-lg btn-success">Jetzt kostenlos registrieren</span></router-link>
            </p>
          </template>
        </template>

        <transition-group appear name="route" mode="out-in">
          <div v-if="subpage=='userdata'" :key="route.fullPath"><DashboardUserdata /></div>
          <div v-if="subpage=='cruises'" :key="route.fullPath"><DashboardBookings /></div>
          <div v-if="subpage=='pricefinder'" :key="route.fullPath"><DashboardPricefinder /></div>
          <div v-if="subpage=='cabinfinder'" :key="route.fullPath"><DashboardCabinfinder /></div>
          <div v-if="subpage=='leaflet'" :key="route.fullPath"><DashboardLeaflet /></div>
        </transition-group>
        <!-- <pre style="white-space: break-spaces; word-wrap: anywhere;">User-Objekt: {{user}}</pre> -->
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { inject, onMounted, onUpdated, ref, watch } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router'
//Components
import DashboardNavigation from '../components/Dashboard/DashboardNavigation.vue';
import DashboardUserdata from '../components/Dashboard/DashboardUserdata.vue';
import DashboardBookings from '../components/Dashboard/DashboardBookings.vue';
import DashboardPricefinder from '../components/Dashboard/DashboardPricefinder.vue';
import DashboardCabinfinder from '../components/Dashboard/DashboardCabinfinder.vue';
import DashboardLeaflet from '../components/Dashboard/DashboardLeaflet.vue';
//Composables


export default {
components: {DashboardNavigation, DashboardUserdata, DashboardBookings, DashboardPricefinder, DashboardCabinfinder, DashboardLeaflet},
props: ['subpage'],
setup(props, context) {

    const user = ref(inject('user'));
    
    const router = useRouter();
    const route = useRoute();
    const subpage = ref(route.params.subpage);

    const error = ref('');
    

    onUpdated(() => {
      subpage.value = route.params.subpage;

    })

    if(!user.value) {
        router.push({ name: 'Home' })
    }

    return { error, user, route, subpage }
  }
}
</script>

<style>

</style>