<template>
  <div id="dashboard-navigation">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <template v-if="user && !user.isAnonymous">
              <router-link class="nav-link" :to="{name:'Dashboard', params:{'subpage':'userdata'}}">Ihre Kontaktdaten &amp; Mitreisenden</router-link>
              <router-link class="nav-link" :to="{name:'Dashboard', params:{'subpage':'cruises'}}">Gebuchte Reisen</router-link>
              <router-link class="nav-link" :to="{name:'Dashboard', params:{'subpage':'pricefinder'}}">Preisfinder</router-link>
              <router-link class="nav-link" :to="{name:'Dashboard', params:{'subpage':'cabinfinder'}}">Kabinenfinder</router-link>              
            </template>
            <router-link class="nav-link" :to="{name:'Dashboard', params:{'subpage':'leaflet'}}">Merkzettel</router-link>
          </div>
        </div>
      </div>      
    </nav>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity'
import { inject } from '@vue/runtime-core';
//Composables

export default {
  setup(props, context) {
    const user = ref(inject('user'));

    return { user }
  }
}
</script>

<style>
.navbar.bg-primary {
  background-color: rgba(var(--bs-primary-rgb),.75) !important;
}
.navbar.navbar-dark .nav-link {
  color:#fff !important;
  border-right: 1px solid rgba(255,255,255,.25)
}
.navbar.navbar-dark .nav-link:hover {
  background-color: rgba(255,255,255,.25);
}
.navbar.navbar-dark .nav-link.router-link-active {
  background-color: rgba(255,255,255,1) !important;
  color: #000 !important;
}
</style>