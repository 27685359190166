<template>
    <div class="container">        
        <div class="backdrop" v-if="showBackdrop"></div>
        <div class="ccontainer">
          <h1>Buchen Sie jetzt Ihre Traumreise</h1>
          <h2>Alle Reisedaten auf einen Blick</h2>
          <CruiseSummary :uniqueTravelId="uniqueTravelId" :showRoute="true" :routeToggleable="true" :routeCollapsed="true" class="mb-3" />
          <h3>Ihr gewählter Kabinen-Typ</h3>
          <CabinSummary :cabinType="cabinType" :cabin="cabin" :pmc="pmc" class="mb-3" />
          <h2>Buchungsformular</h2>
          <BookingForm @blockingOn="blockContent(true)" @blockingOff="blockContent(false)" hanseat="hanseat" />
        </div>       
    </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity'
//Components
import BookingForm from '../components/Booking/BookingForm.vue';
import CruiseSummary from '../components/Summaries/CruiseSummary.vue';
import CabinSummary from '../components/Summaries/CabinSummary.vue';
import { provide } from '@vue/runtime-core'
import helperFunctions from '../composables/helperFunctions';
//Composables

export default {
  props: ['uniqueTravelId', 'hanseat', 'baid', 'oid', 'chooseCabin'],
  components: { BookingForm, CruiseSummary, CabinSummary },
  setup(props, context) {
    //console.log(props);
    const { getBookingData } = helperFunctions();
    const bookingData = getBookingData();

    const uniqueTravelId = ref(props.uniqueTravelId);
    const hanseat = ref(props.hanseat);
    const cabinType = ref('');
    const cabin = ref('');
    const pmc = ref('');

    if(typeof uniqueTravelId.value === 'undefined') uniqueTravelId.value = bookingData.uniqueTravelId;
    cabinType.value = bookingData.cabinType;
    cabin.value = bookingData.cabin;
    pmc.value = bookingData.pmc;

    provide('bookingUniqueTravelId', uniqueTravelId.value);
    provide('bookingBaid', bookingData.baid);
    provide('bookingOid', bookingData.oid);
    provide('bookingChooseCabin', bookingData.chooseCabin);

    const showBackdrop = ref(false);
    const blockContent = (status) => {  //triggered by child component(s) to shade the background
      showBackdrop.value = status;
    }

    return { showBackdrop, blockContent, uniqueTravelId, cabinType, cabin, pmc, hanseat };
  }
}
</script>

<style>

</style>