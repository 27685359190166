import { computed } from "vue";
import { useStore } from "vuex";

// ======================== VUEX STORE HELPER FUNCTIONS =======================

const mapState = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store.state).map(
      key => [key, computed(() => store.state[key])]
    )
  )
}

const mapGetters = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store.getters).map(
      getter => [getter, computed(() => store.getters[getter])]
    )
  )
}

const mapMutations = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._mutations).map(
      mutation => [mutation, value => store.commit(mutation, value)]
    )
  )
}

/**
* Lets us use Vuex mutations easier. Instead of const inc = () => store.commit(counter.value+1) we just need to call const {setCounter} = useMutations(['setCounter']); const inc = () => setCounter(counter.value + 1)
* @param {*} arr 
* @returns 
*/
const mapMutationsMainJs = (arr, store) => {
  const keypair = arr.map(m => [m, input => store.commit(m, input)]);
 return Object.fromEntries(keypair);
}   

const mapActions = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._actions).map(
      action => [action, value => store.dispatch(action, value)]
    )
  )
}

export { mapState, mapGetters, mapMutations, mapMutationsMainJs, mapActions }