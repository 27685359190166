//Composables
import helperFunctions from './helperFunctions';

/**
 * 
 * @param {Object} pricesFromApi Prices object for either cabins only or cabins with flight
 * @param {Object} lang Language object of currently set language
 * @returns {Array} Array of objects with prices for inner, outer, balcony and suite cabins
 */
export function parseCruiseDetailsPricesForDisplay(pricesFromApi, lang, htmlIdentifier) {
  const { convertYMDHISToDate } = helperFunctions();

  let pricesInnerCabins = [];
  let pricesOuterCabins = [];
  let pricesBalconyCabins = [];
  let pricesSuites = [];
  let allPrices = [];
  
  let x = 0;

  Object.keys(pricesFromApi.prices).forEach((key) => {
    let dateFormatOptions = {weekday:'short', year: 'numeric', month: 'numeric', day: 'numeric'};
    pricesFromApi.prices[key].startDateFormatted = convertYMDHISToDate(pricesFromApi.prices[key].startDate).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);
    pricesFromApi.prices[key].endDateFormatted = convertYMDHISToDate(pricesFromApi.prices[key].endDate).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);

    pricesFromApi.prices[key].uniqueFrontendId = x;

    switch(pricesFromApi.prices[key].cabinType) {
      case 1:
        //Inner cabin
        pricesFromApi.prices[key].cabinTypeText = lang.cabinTypes[1];  //Setting human-readable Cabin Type like 'Innenkabine'
        pricesInnerCabins.push(pricesFromApi.prices[key]); //Builds up a new array containing only cabin type 1 prices
        break;
      case 2:
        //Outer cabin
        pricesFromApi.prices[key].cabinTypeText = lang.cabinTypes[2];
        pricesOuterCabins.push(pricesFromApi.prices[key]);
        break;
      case 3:
        //Balcony cabin
        pricesFromApi.prices[key].cabinTypeText = lang.cabinTypes[3];
        pricesBalconyCabins.push(pricesFromApi.prices[key]);
        break;
      case 4:
        //Suite
        pricesFromApi.prices[key].cabinTypeText = lang.cabinTypes[4];
        pricesSuites.push(pricesFromApi.prices[key]);
        break;
      default:
        pricesFromApi.prices[key].cabinTypeText = lang.cabinTypes[0];
    }

    x++;
  });

  //Populare allPrices array only with actually available cabins - grouped by inner/outer/balcony/suite cabintype
  let num=1;
  if(pricesInnerCabins.length>0) {
    allPrices.push({prices: pricesInnerCabins, selector: htmlIdentifier+'inner', tabName: 'Innenkabinen', cabinType: 1, num: num});
    num++;
  }
  if(pricesOuterCabins.length>0) {
    allPrices.push({prices: pricesOuterCabins, selector: htmlIdentifier+'outer', tabName: 'Außenkabinen', cabinType: 2, num: num});
    num++;
  }
  if(pricesBalconyCabins.length>0) {
    allPrices.push({prices: pricesBalconyCabins, selector: htmlIdentifier+'balcony', tabName: 'Balkonkabinen', cabinType: 3, num: num});
    num++;
  }
  if(pricesSuites.length>0) {
    allPrices.push({prices: pricesSuites, selector: htmlIdentifier+'suite', tabName: 'Suiten', cabinType: 4, num: num});
    num++;
  }
    return allPrices;
}

export function calculatePriceCruiseOnly(priceArray, adults = 0, children = []) {
  let prices = {};
  prices.adultPrice = [];
  prices.childPrice = [];
  prices.totalPrice = 0.0;

  let p = parsePricesFloatToNewObject(priceArray);
  
  if (adults == 1 && children.length == 0) { // single journey
    // console.log('Price calculation: single adult journey');
    prices.adultPrice.push(p.priceCruiseSingle);
  }

  if (adults == 2 && children.length == 0) { // two people journey
    // console.log('Price calculation: two adults journey');
    prices.adultPrice.push(p.priceCruise, p.priceCruise);
  }

  if (adults == 1 && children.length >= 1) { // one adult + one or more children
    // console.log('Price calculation: one adult + one or more children');
    prices.adultPrice.push(p.priceCruise);
    prices.childPrice.push(p.priceCruiseChild);
    if(children.length > 1) prices.childPrice.push(p.priceCruiseChild34);
    if(children.length > 2) prices.childPrice.push(p.priceCruiseChild34);
  }

  if (adults == 0 && children.length > 0) {
    // console.log('Price calculation: no adults, only children journey');
    let i = 0;
    for(let child in children) {
      if (i == 0 || i == 1) {
        // check if junior or child (TODO)
        prices.childPrice.push(p.priceCruiseChild);
      } else {
        // check if junior or child (TODO)
        prices.childPrice.push(p.priceCruiseChild34);
      }
      $i++;
    }
  }

  if (adults > 1 && children.length >= 1) {
    // console.log('Price calculation: More than 1 adult + 1 or more children journey');
    for (let i = 0; i < adults; i++) {
      prices.adultPrice.push(p.priceCruise);
    }
    for (let child in children) {
      // check if junior or child (TODO)
      prices.childPrice.push(p.priceCruiseChild34);
    }
  }

  // total price:
  if (prices.adultPrice.length) {
    prices.adultPrice.forEach((element) => {
      prices.totalPrice += element;
    });
  }

  if (prices.childPrice.length) {
    prices.childPrice.forEach((element) => {
      prices.totalPrice += element;
    });
  }
  return prices;
}

export function calculatePriceCruiseWithFlight(priceArray, adults = 0, children = []) {
  let prices = {};
  prices.adultPrice = [];
  prices.childPrice = [];
  prices.totalPrice = 0.0;

  let p = parsePricesFloatToNewObject(priceArray);

  if (adults == 1 && children.length == 0) { // single journey
    // console.log('Price calculation: single adult journey with flight');
    prices.adultPrice.push(p.priceCruiseSingle + p.priceFlightSingle);
  }

  if (adults == 2 && children.length == 0) { // two people journey
    // console.log('Price calculation: two adults journey with flights');
    prices.adultPrice.push(p.priceTotal, p.priceTotal);
  }

  if (adults == 1 && children.length >= 1) { // one adult + one or more children
    // console.log('Price calculation: one adult + one or more children with flights');
    prices.adultPrice.push(p.priceTotal);
    prices.childPrice.push(p.priceCruiseChild + p.priceFlightChild);
    if(children.length > 1) prices.childPrice.push(p.priceCruiseChild34 + p.priceFlightChild34);
    if(children.length > 2) prices.childPrice.push(p.priceCruiseChild34 + p.priceFlightChild34);
  }

  if (adults == 0 && children.length > 0) {
    // console.log('Price calculation: no adults, only children journey with flights');
    let i = 0;
    for(let child in children) {
      if (i == 0 || i == 1) {
        // check if junior or child (TODO)
        prices.childPrice.push(p.priceCruiseChild + p.priceFlightChild);
      } else {
        // check if junior or child (TODO)
        prices.childPrice.push(p.priceCruiseChild34 + p.priceFlightChild34);
      }
      $i++;
    }
  }  

  if (adults > 1 && children.length >= 1) {
    // console.log('Price calculation: More than 1 adult + 1 or more children journey with flights');
    for (let i = 0; i < adults; i++) {
      prices.adultPrice.push(p.priceTotal);
    }
    for (let child in children) {
      // check if junior or child (TODO)
      prices.childPrice.push(p.priceCruiseChild34 + p.priceFlightChild34);
    }
  }  

  // total price:
  if (prices.adultPrice.length) {
    prices.adultPrice.forEach((element) => {
      prices.totalPrice += element;
    });
  }

  if (prices.childPrice.length) {
    prices.childPrice.forEach((element) => {
      prices.totalPrice += element;
    });
  }  
  
  return prices;
}

function parsePricesFloatToNewObject(priceArray) {
    // console.log(priceArray)
  let floatPrices = {};
  Object.keys(priceArray).forEach((key) => { //parses all numeric values to float        
    if(isNumber(priceArray[key])) floatPrices[key] = parseFloat(priceArray[key]);
  });
    // console.log(floatPrices);
  return floatPrices;
}

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }