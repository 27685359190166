<template>
  <!-- <div>
    Anzahl Routen: {{routesCount}}
  </div> -->
  <div>
    <div v-for="route in routes" :key="route.routeId">
      <div>
        <h5>{{route.routeName}}</h5>
      </div>
      <hr>
      <div>
        <table class="table">
          <tr>
            <th>Reise-Tag</th>
            <th>Land/Hafen</th>
            <th>Ankunft</th>
            <th>Abfahrt</th>
          </tr>
          <tr v-for="dest in route.destinations" :key="dest">
            <td>Tag {{dest.routeDestinationDay}}</td>
            <td>{{dest.routeDestinationName}}, {{dest.routeDestinationCountry}}</td>
            <td>{{(dest.routeDestinationStart!='00:00:00') ? dest.routeDestinationStart : ''}}</td>
            <td>{{(dest.routeDestinationEnd!='00:00:00') ? dest.routeDestinationEnd : ''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { inject, onMounted } from '@vue/runtime-core';
//Composables
import useMetaAPI from '../../composables/useMetaAPI'

export default {
  props: ['uniqueTravelId'],
  setup(props, context) {
    const JWTToken = ref(inject('JWTToken'));
    const error = ref('');

    const uniqueTravelId = ref('')

    const routes = ref([]);
    const routesCount = ref(0);

    const {MetaApiError, getRoute} = useMetaAPI();

    onMounted(() => {
      if(typeof props.uniqueTravelId !== 'undefined') {
        uniqueTravelId.value = props.uniqueTravelId;

        getRoute(uniqueTravelId.value, JWTToken.value)
          .then((result) => {
            if(!result) {
              console.log('Route is unavailable.');
            } else {
              routes.value = result.routes;

              Object.keys(routes.value).forEach((key) => {
                routes.value[key].numDestinations = Object.keys(routes.value[key].destinations).length;
              });
              routesCount.value = result.routesCount;
            }
          })
          .catch((err) => { console.log(err), error.value += MetaApiError });      
      } 
    });

    // console.log(routes);
    return {uniqueTravelId, routes, routesCount}
  }
}
</script>

<style>

</style>