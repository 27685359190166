
/*
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
    @applyFilters
    * Applies given filterList to given cruiseList and returns the resulting cruises.
    ! if no filterList is given the function will throw a Exception once the default has been removed.
    ? Possibly keep a default filter set?
    @Params
        {Object, Array}	cruiseList
        |> List of Searchresults
        {Object, Array}	filterList
        |> List of Searchparameters
    @Returns
        {Object}
        |> Filtered list of searchresults
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
*/
export function applyFilters(cruiseList, filterList = {1: 1, 2: 1, 3: 0, 4: 1}) {
  let conversionArray = [];
  let tempCl = {
      totalCruisesCount: 0,
      cruises: {}
  };
  let cl;
  let ff;
  if(Array.isArray(filterList)) {
      ff = filterList;
  } else if(typeof filterList === 'object') {
      ff = Object.entries(filterList);
  } else {
      throw new Exception('filterList is of incorrect Type');
  }

  if(Array.isArray(cruiseList)) {
      cl = cruiseList;
  } else if(typeof cruiseList === 'object') {
      cl = Object.entries(cruiseList);
  } else {
      throw new Exception('cruiseList is of incorrect Type');
  }

  cl.forEach((singleCruise) => {
      let c = 0;

      if(typeof singleCruise.filters === 'undefined') singleCruise.filters = [];

      if((typeof singleCruise.filters !== 'undefined') && (Array.isArray(singleCruise.filters) === false)) {
        Object.entries(singleCruise.filters).forEach((scFilter) => {
            ff.forEach((filter) => {
                if(scFilter[0] === filter[0]) {
                    if(scFilter[1] === filter[1]) {
                        c++;
                    }
                }
            })
        });
      } else {
        console.log('SingleCruise does not Include Filter or is not of type Object');
      }
      
      if(c >= ff.length) {
          conversionArray.push(singleCruise);
      }
  });
  Object.assign(tempCl.cruises, conversionArray);
  tempCl.totalCruisesCount = conversionArray.length;
  
  return tempCl;
}

export function filterSettings(userId = null, filterList = null) {
  if(filterList !== null) {
      if(userId === null) {
          return (typeof sessionStorage.getItem('cFilters') !== 'undefined')
                  ? sessionStorage.getItem('cFilters') 
                  : false;
      } else {
          /*
              Get Data from User Object
          */
      }
  } else {
      if(userId !== null) {
          /*

          */
      } else {
          if(typeof filterList === 'object') {
              sessionStorage.setItem('cFilters', filterList);
          }
      }
  }
}