import { ref } from "vue"
import { projectAuth, googleAuthProvider, facebookAuthProvider, appleAuthProvider } from "../config/firebase"

const logonError = ref(null)
const verificationResponse = ref(null);

const getUser = () => {
  const user = ref(projectAuth.currentUser)
  projectAuth.onAuthStateChanged((firebaseUser) => { 
    if(firebaseUser) {
      let plainUserObject = JSON.parse(JSON.stringify(firebaseUser));
      firebaseUser.accessToken = plainUserObject.stsTokenManager.accessToken;
    }

    console.log('Current user is: ', firebaseUser)  
    user.value = firebaseUser // firebaseUser wird immer zurückgegeben, wenn eine Authentifizierungs-Änderung stattfindet. Ist der User nicht eingeloggt, wird Null zurückgegeben
  });
  return user;
}

const login = async (email, password) => {
  logonError.value = null

  try {
    const response = await projectAuth.signInWithEmailAndPassword(email, password)
    logonError.value = 0  //no error
    console.log(response)
    if (response.user.emailVerified !== true) logonError.value = 2; //email address not verified yet
    return response
  } catch(err) {
    console.log(err.value)
    logonError.value = 1  //wrong credentials or other error
  }
}

const logout = async () => {
  logonError.value = null
  try {
    await projectAuth.signOut();
  } catch(err) {
    console.log(err)
    logonError.value = err.message
  }
}

const signup = async (email, password, displayName) => {
  logonError.value = null

  try {
    const response = await projectAuth.createUserWithEmailAndPassword(email, password)

    if(!response) {
      throw new Error('Could not complete signup')
    }

    await response.user.updateProfile({ displayName: displayName })

    sendEmailVerificationMail(response.user);

    logonError.value = null

    console.log(response.user)

    return response

  } catch(err) {
    console.log(err.message)
    logonError.value = err.message
  }
}
  
const sendEmailVerificationMail = async (firebaseUser) => {
  verificationResponse.value = null;

  if (firebaseUser) {
    if (firebaseUser.emailVerified != true) {
      let ret = await firebaseUser.sendEmailVerification()
        .then(function() {
          console.log('User E-Mail Verification sent.');
          verificationResponse.value = [0, 'Die E-Mail zur Account-Verifizierung wurde erfolgreich versendet.'];
          return true;
        }, function(error) {
          switch(error.code) {
            case "auth/too-many-requests":
              verificationResponse.value = [1, 'Die E-Mail zur Account-Verifizierung konnte nicht gesendet werden, da sie zu oft angefordert wurde. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'];
              console.log('User E-Mail Verification had not been sent. Reason: Too many requests!');
              break;
            default:
              verificationResponse.value = [2, 'Die E-Mail zur Account-Verifizierung konnte aus technischen Gründen nicht gesendet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'];
              console.log('User E-Mail Verification had not been sent.', error);
          }
          return false;
        });
        return ret;
      } else {
        verificationResponse.value = [3, 'Der Account wurde bereits erfolgreich per E-Mail Verifizierung bestätigt.'];
        console.log('User has already verified E-Mail address.');
        return true;
      }
  } else {
    verificationResponse.value = [4, 'Sie sind leider nicht angemeldet. Bitte melden Sie sich zuerst am System an.'];
    console.log('No valid Firebase User');
    return false;
  }  
}

const googleLogin = () => {
  projectAuth.signInWithPopup(googleAuthProvider)
    .then((result) => {
      logonError.value = 0  //no error
      let token = result.credential.accessToken;
      let user = result.user;
        console.log(token) // Token
        console.log(user) // User that was authenticated
        return result;
    })
    .catch((err) => {
      console.log(err); // This will give you all the information needed to further debug any errors
      logonError.value = 1  //wrong credentials or other error
    });
};

const facebookLogin = () => {
  projectAuth.signInWithPopup(facebookAuthProvider)
    .then((result) => {
      logonError.value = 0  //no error
      let token = result.credential.accessToken;
      let user = result.user;
        console.log(token) // Token
        console.log(user) // User that was authenticated
        return result;
    })
    .catch((err) => {
      console.log(err); // This will give you all the information needed to further debug any errors
      logonError.value = 1  //wrong credentials or other error
    });
}

const appleLogin = () => {
  appleAuthProvider.addScope('email');
  appleAuthProvider.addScope('name');
  appleAuthProvider.setCustomParameters({
    locale:'de'
  })
  projectAuth.signInWithPopup(appleAuthProvider)
    .then((result) => {
      logonError.value = 0  //no error
      let credential = result.credential;
      let token = result.credential.accessToken;
      let user = result.user;
      let idToken = credential.idToken;
        console.log(token) // Token
        console.log(user) // User that was authenticated
        return result;
    })
    .catch((err) => {
      console.log(err); // This will give you all the information needed to further debug any errors
      logonError.value = 1  //wrong credentials or other error
    });
}

const anonymousLogin = () => {
  projectAuth.signInAnonymously()
  .then((result) => {
    logonError.value = 0;
    let user = result.user;
    // console.log(user);
    console.log('Anonymous User logged in');
    // console.log(result);
    return result;
  })
  .catch((err) => {
    console.log(err);
    logonError.value = 1
  });
}

const useFirebaseLogon = () => {
  return  { 
    logonError, 
    getUser,
    login, logout, signup, sendEmailVerificationMail, verificationResponse,
    googleLogin, facebookLogin, appleLogin, anonymousLogin
  }
}

export default useFirebaseLogon