<template>
  <div class="welcome-screen">
      <h2>Willkommen beim Prototypen</h2>
      <p>Über "Reisen suchen" können Sie eine Suche starten.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>