<template>
  <h1>Oha, da ist was schief gelaufen! (´･_･`) </h1>
  <p>{{errorMessage}}</p>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props: ['errorMessage'],
  setup(props) {
    const errorMessage = ref(props.errorMessage);

    return { errorMessage }
  }
}
</script>

<style>

</style>