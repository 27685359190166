import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


const firebaseConfig = {
  //Firebase Projekt: seereisen-de-vue-prototyp || Google-Konto: ingo.heydkamp@urlaubsexperte.de
  // apiKey: "AIzaSyAG-A7k0br31Cv3lJiqWOhcUzP6Id91AR0",
  // authDomain: "seereisen-de-vue-prototype.firebaseapp.com",
  // projectId: "seereisen-de-vue-prototype",
  // storageBucket: "seereisen-de-vue-prototype.appspot.com",
  // messagingSenderId: "806036810974",
  // appId: "1:806036810974:web:4d55b852dc26f0a335664f",
  // measurementId: "G-6FGQ0SNCH2"
  apiKey: "AIzaSyCa6Q96bYAmdSDCT33VWOIINiyL8RqeFEE",
  authDomain: "login-seereisen-de.firebaseapp.com",
  projectId: "login-seereisen-de",
  storageBucket: "login-seereisen-de.appspot.com",
  messagingSenderId: "307617255741",
  appId: "1:307617255741:web:9d0a782559770dcf523554",
  measurementId: "G-ZG9DZ49BDG"
};

//init firebase
firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { 
  projectAuth, projectFirestore, 
  googleAuthProvider, facebookAuthProvider, appleAuthProvider, 
  timestamp 
}