<template>
  <div class="cruises-list mt-3" ref="cruisesList">
    <div v-if="cruises">
      <div  class="d-block d-md-flex justify-content-between">
        <h3>{{numRecords}} Reise{{(numRecords>1) ? 'n' : ''}} gefunden</h3>
        <Pagination v-model="currentPage" :records="numRecords" :per-page="recordsPerPage" @paginate="paginate" :options="paginationOptions" />
      </div>
      <div>
        <CruiseSorter :cruiseList="cruiseList" @updateCruiseList="updateCruiseList" />
      </div>
      <div v-if="numRecords>265" class="badge bg-danger">Hinweis: Es wurden mehr als 265 Reisen gefunden - bitte schränken Sie Ihre Suchfilter weiter ein.</div>
        <transition-group appear @before-enter="beforeEnterCruises" @enter="enterCruises" tag="div">
          <div v-for="(cruise, index) in currentCruises" :key="cruise" :data-index="index">
            <SingleCruise :cruise="cruise" :leafletData="leafletData" />
          </div>
        </transition-group>
      <div class="d-block d-md-flex justify-content-between">
        <h3>{{numRecords}} Reise{{(numRecords>1) ? 'n' : ''}} gefunden</h3>
        <Pagination v-model="currentPage" :records="numRecords" :per-page="recordsPerPage" @paginate="paginate" :options="paginationOptions" />
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import Pagination from 'v-pagination-3';
import gsap from 'gsap';
//Components
import SingleCruise from './SingleCruise.vue';
import CruiseSorter from './CruiseSorter.vue';
//Composables

export default {
  props: ['cruises', 'leafletData'],
  components: { SingleCruise, Pagination, CruiseSorter },

  setup(props) {
    const router = useRouter();

    if(!props.cruises) {
      router.push({name:'Home'})
      return;  //abort if no cruises were passed
    } 

    const cruiseList = ref(props.cruises);
    const cruises = ref(props.cruises.cruises);
    const currentCruises = ref();
    const cruisesList = ref(null);

    const leafletData = ref(props.leafletData);

    const currentPage = ref(1);
    const recordsPerPage = ref(10);
    const numRecords = props.cruises.totalCruisesCount;
    let numTotalRecords = props.cruises.totalCruisesCount;

    //PAGINATION DOCU: https://www.npmjs.com/package/v-pagination-3
    const paginationOptions = ref({
      chunk: 5,
      theme: 'bootstrap4',
      edgeNavigation: true,
      hideCount: false,
      texts: {
        first: 'Anfang',
        last: 'Ende',
        count: 'Sie sehen die Reisen {from} bis {to} von insgesamt {count} Reisen||'
      }
    });

    const updateCruiseList = (updatedCruiseList) => {
      // console.log('emit fired, cruise list updated', updatedCruiseList)
      cruiseList.value = updatedCruiseList;
      cruises.value = updatedCruiseList.cruises;
      numTotalRecords = updatedCruiseList.totalCruisesCount;    
      paginate();
    };

    onMounted(() => {      
      paginate();
    });

    const paginate = () => {
      //console.log('pagination event fired. Current page:', currentPage.value);
      let sliceFrom = (currentPage.value*recordsPerPage.value-(recordsPerPage.value-1))-1;
      let sliceTo = (currentPage.value*recordsPerPage.value)-1;
      currentCruises.value = Object.entries(cruises.value).slice(sliceFrom, sliceTo).map(entry => entry[1]);
      var bodyRect = document.body.getBoundingClientRect();
      var cruiseListRect = cruisesList.value.getBoundingClientRect();
      window.scrollTo(0,cruiseListRect.top-bodyRect.top);
    }


    const beforeEnterCruises = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateX(100px)';
      el.style.backgroundColor = '#2777bd';
    }
    const enterCruises = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        backgroundColor: 'transparent',
        duration: 0.5,
        onComplete: done,
        delay: el.dataset.index * 0.2 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }

    return { beforeEnterCruises, enterCruises, cruisesList, cruiseList, cruises, leafletData, currentPage, recordsPerPage, numRecords, numTotalRecords, paginationOptions, updateCruiseList, paginate, currentCruises };
  }
}
</script>

<style scoped>
  .cruises-list {
    width:100%
  }
</style>