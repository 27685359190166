/**
 * Defines translations and conversion settings for different languages that the user can choose from
 */

const languages = [];

//German
languages['DE'] = {
  langName: 'DE',
  dateTimeLang: 'de-DE',

  monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
  monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],

  cabinTypes: {0: 'k.A.', 1: 'Innenkabine', 2: 'Außenkabine', 3: 'Balkonkabine', 4: 'Suite'},

  ccBookingFields: {
    registrant_title: 'Anrede',
    registrant_firstname: 'Vorname',
    registrant_lastname: 'Nachname',
    registrant_street: 'Straße',
    registrant_street_number: 'Hausnummer',
    registrant_city: 'Stadt',
    registrant_zipcode: 'Plz',
    registrant_phone: 'Telefon',
    registrant_email: 'E-Mail',
    registrant_mobile: 'Mobil',
    traveler1_title: 'Anrede',
    traveler1_firstname: 'Vorname',
    traveler1_lastname: 'Nachname',
    traveler1_birthday: 'Geburtsdatum',
    traveler1_nationality: 'Nationalität',
    traveler2_title: 'Anrede',
    traveler2_firstname: 'Vorname',
    traveler2_lastname: 'Nachname',
    traveler2_birthday: 'Geburtsdatum',
    traveler2_nationality: 'Nationalität',
    traveler3_title: 'Anrede',
    traveler3_firstname: 'Vorname',
    traveler3_lastname: 'Nachname',
    traveler3_birthday: 'Geburtsdatum',
    traveler3_nationality: 'Nationalität',
    traveler4_title: 'Anrede',
    traveler4_firstname: 'Vorname',
    traveler4_lastname: 'Nachname',
    traveler4_birthday: 'Geburtsdatum',
    traveler4_nationality: 'Nationalität',
    traveler5_title: 'Anrede',
    traveler5_firstname: 'Vorname',
    traveler5_lastname: 'Nachname',
    traveler5_birthday: 'Geburtsdatum',
    traveler5_nationality: 'Nationalität',                
    traveler_count: 'Anzahl Reisende Personen',
    cc_operator_tos: 'AGB des Reiseveranstalters',
    cc_operator_covid: 'Medizinischer Hinweis zu COVID-19',
    cc_operator_form : 'Formblatt zu Pauschalreisen',
    cc_tour_name : 'Reisename',
    cc_operator_name : 'Reiseveranstalter',
    cc_operator_phone : 'Telefon',
    cc_operator_street : 'Straße',
    cc_operator_city : 'Stadt',
    cc_operator_zipcode : 'Plz',
    cc_operator_country : 'Land',
    cc_operator_email : 'E-Mail',
    cc_operator_website : 'Veranstalter Website'
  }
};

//English
languages['EN'] = {
  langName: 'EN',
  dateTimeLang: 'en-US',

  monthNames: ['January','February','March','April','May','June','July','August','September','October','November','December'],
  monthNamesShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  
  cabinTypes: {0: 'n.A.', 1: 'Inside cabin', 2: 'Outside cabin', 3: 'Balcony cabin', 4: 'Suite'},

  ccBookingFields: {
    registrant_title: 'Title',
    registrant_firstname: 'First name',
    registrant_lastname: 'Last name',
    registrant_street: 'Street',
    registrant_street_number: 'Street number',
    registrant_city: 'City',
    registrant_zipcode: 'Zip',
    registrant_phone: 'Telephone',
    registrant_email: 'E-Mail',
    registrant_mobile: 'Mobile',
    traveler1_title: 'Title',
    traveler1_firstname: 'First name',
    traveler1_lastname: 'Last name',
    traveler1_birthday: 'Birthday',
    traveler1_nationality: 'Nationality',
    traveler2_title: 'Title',
    traveler2_firstname: 'First name',
    traveler2_lastname: 'Last name',
    traveler2_birthday: 'Birthday',
    traveler2_nationality: 'Nationality',
    traveler3_title: 'Title',
    traveler3_firstname: 'First name',
    traveler3_lastname: 'Last name',
    traveler3_birthday: 'Birthday',
    traveler3_nationality: 'Nationality',
    traveler4_title: 'Title',
    traveler4_firstname: 'First name',
    traveler4_lastname: 'Last name',
    traveler4_birthday: 'Birthday',
    traveler4_nationality: 'Nationality',
    traveler5_title: 'Title',
    traveler5_firstname: 'First name',
    traveler5_lastname: 'Last name',
    traveler5_birthday: 'Birthday',
    traveler5_nationality: 'Nationality',                
    traveler_count: 'Total traveling persons',
    cc_operator_tos: 'Tour operator\'s TOS',
    cc_operator_covid: 'COVID-19 medical advices',
    cc_operator_form : 'Official form for all expense tours',
    cc_tour_name : 'Cruise name',
    cc_operator_name : 'Tour operator',
    cc_operator_phone : 'Telephone',
    cc_operator_street : 'Street',
    cc_operator_city : 'City',
    cc_operator_zipcode : 'Zip',
    cc_operator_country : 'Country',
    cc_operator_email : 'E-Mail',
    cc_operator_website : 'Tour operator website'    
  }  
};

function getLanguage(langCode) {
  return languages[langCode];
}

const languageFunctions = () => {
  return  { getLanguage };
}

export default languageFunctions;
