<template>
  <div class="login-form">
    <p>
      Loggen Sie sich in Ihr persönliches Seereisen.de-Dashboard ein und verwalten Sie Ihre Reisebuchungen und legen Sie individuelle Benachrichtigungen
      an, wenn Ihre Wunschkabine oder Reise zu einem bestimmten Preis buchbar ist.
    </p>
    <form @submit.prevent="handleSubmit">
      <div class="input-group mb-3">
        <span class="input-group-text w-50">E-Mail Adresse</span>
        <input class="form-control" type="email" required placeholder="E-Mail" v-model="email"> 
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text w-50">Passwort</span>
        <input class="form-control" type="password" required placeholder="Password" v-model="password">
      </div>
      <button class="btn btn-primary">Jetzt einloggen</button>
      <div class="error" v-if="logonError!=null && logonError!=0">{{ logonError }}</div>
    </form>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useFirebaseLogon from '../../composables/useFirebaseLogon'

export default {
  setup(props, context) {
    const email = ref('')
    const password = ref('')


    const { logonError, login } = useFirebaseLogon()

    const handleSubmit = async () => {
      await login(email.value, password.value)
      switch(logonError.value) {
        case 0: //no error
          console.log('user logged in');
          context.emit('login');
          break;
        case 1: //wrong credentials or other error
          console.log('user NOT logged in.')
          // context.emit('loginerror');
          break;
        case 2:
          console.log('Email Address not verified yet.');
          context.emit('verifyemailaddress');
      }
    }



    return { email, password, handleSubmit, logonError }
  }
}
</script>

<style>

</style>