<template>
  <div class="container">
    <div class="ccontainer col-8">
      <div v-if="showLogin===true">
        <h2>Login</h2>
        <LoginForm @login="enterDashboard" @loginerror="loginError" @verifyemailaddress="showEmailVerificationForm" />
        <p class="mt-2">Noch kein Account? <span class="link link-primary" @click="showLogin = !showLogin">Jetzt registrieren</span>.</p>
      </div>
      <div v-else>
        <h2>Registrierung</h2>
        <SignupForm @signup="showEmailVerificationForm" />
        <p class="mt-2">Bereits registriert? <span class="link link-primary"  @click="showLogin = !showLogin">Jetzt einloggen</span>.</p>
      </div>

      <hr>
      <div class="my-5">
        <p>Sie können Sich auch bequem mit einem bestehenden Konto über einen der folgenden Dienstanbieter bei uns einloggen:</p>
        <div class="d-flex p-1">
          <button @click.prevent="startGoogleLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Google" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" /> Login mit Google
          </button>
          <button @click.prevent="startFacebookLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Facebook" src="https://upload.wikimedia.org/wikipedia/commons/9/91/036-facebook.png" /> Login mit Facebook
          </button>  
          <button @click.prevent="startAppleLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Apple ID" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Apple-logo.png/240px-Apple-logo.png" /> Login mit Apple ID
          </button>                    
        </div>      
      </div>
    </div>

    <div id="modal_wishlist" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Möchten Sie Ihren Merkzettel übernehmen?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Sie haben mindestens eine Reise auf Ihrem temporären Merkzettel notiert.</p>
            <p>Möchten Sie diese Reise(n) auf den dauerhaften Merkzettel in Ihrem Benutzerkonto übernehmen?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="inheritWishlist">Ja, gerne</button>
            <button type="button" class="btn btn-secondary" @click="dismissModal">Nein, danke</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { inject } from '@vue/runtime-core';
import { mapGetters } from '../composables/useVuexStore';
import store from '../store/store';
//Components
import SignupForm from '../components/Login/SignupForm.vue';
import LoginForm from '../components/Login/LoginForm.vue';
import useFirebaseLogon from '../composables/useFirebaseLogon';
import useHelperFunctions from '../composables/helperFunctions';
//Composables
import useLoginAPI from '../composables/useLoginAPI';


export default {
  props: ['showLogin'],
  components: { SignupForm, LoginForm },
  setup(props) {

    const showLogin = ref((typeof props.showLogin !== 'undefined') ? props.showLogin :  true);
    const router = useRouter();
    const wishlist = ref([]);

    const { googleLogin, facebookLogin, appleLogin } = useFirebaseLogon();
    const { getLeafletData, removeCruiseFromLeaflet } = useHelperFunctions();
    const { addToWishlist } = useLoginAPI();

    /**
     * Function to be called after user logged in
     */
    const enterDashboard = () => { 
      wishlist.value = getLeafletData();
      const user = store.getters.getUser;
      
      if(wishlist.value !== null && wishlist.value.length > 0 && user && !user.isAnonymous) {
        //Ask user whether he wants to convert his temporary wishlist to permanent or not
        document.getElementById('modal_wishlist').style.display = "block";
      } else {
        router.push({ name: 'Dashboard' });  
      }      
    };

    const inheritWishlist = () => {
      console.log('Transferring wishlist items from anonymous user to registered user account.');
      const AccessToken = store.getters.getAccessToken;
      wishlist.value.forEach((val, index) => {
        addToWishlist(AccessToken, val)
          .then((result) => {
            console.log('Cruise ' + val + ' transferred to permanent wishlist');
            removeCruiseFromLeaflet(val);
          });
        });
      router.push({ name: 'Dashboard' });
    }

    const dismissModal = () => {
      document.getElementById('modal_wishlist').style.display = "none";
      router.push({ name: 'Dashboard' });
    }

    const loginError = () => {
      //not used. Errors are currently displayed by LoginForm.vue
    };

    const showEmailVerificationForm = () => {
      router.push({name: "EmailVerification" });
    }

    const startGoogleLogin = () => {
      let response = googleLogin();
      if(response) enterDashboard();
    };   
    
    const startFacebookLogin = () => {
      let response = facebookLogin();
      if(response) enterDashboard();
    };  
    
    const startAppleLogin = () => {
      let response = appleLogin();
      if(response) enterDashboard();
    };      

    return { 
      showLogin, enterDashboard, loginError, showEmailVerificationForm, 
      startGoogleLogin, startFacebookLogin, startAppleLogin,
      inheritWishlist, dismissModal
      };
  }
}
</script>

<style>

</style>