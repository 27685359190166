<template>
  <div class="booking-form">    
      <div class="error" v-if="error!=''">{{ error }}</div>

      <!-- BOOKING OVERVIEW AFTER SUCCESSFUL BOOKING REQUEST -->
      <div v-if="bookingRequestDone && !finalBookingDone">
        <template v-if="bookingRequestSuccessful">
          <div class="booking-successful mb-5 p-5 border border-success">
            <h3 class="text-success">Nur noch ein letzter Schritt zur Buchung!</h3>
            <h4>Ihre Angaben wurden erfolgreich geprüft - Nur noch ein letzter Schritt zur verbindlichen Buchung Ihrer Reise ist nötig.</h4>
            
            <template v-if="hanseat != 1">
            <p>Hier finden Sie die ersten Reise-Informationen für Sie zum Download:</p>
            <ul>
              <li v-if="typeof bookingRequestResponse.travelRequirements != 'undefined' && bookingRequestResponse.travelRequirements.DE"><a :href="bookingRequestResponse.travelRequirements.DE" target="_blank">Reisebedingungen [PDF]</a></li>
              <li v-if="bookingRequestResponse.tourOperatorCovid"><a :href="bookingRequestResponse.tourOperatorCovid" target="_blank">Medizinischer Hinweis zu COVID-19 [PDF]</a></li>
              <li v-if="bookingRequestResponse.tourOperatorForm"><a :href="bookingRequestResponse.tourOperatorForm" target="_blank">Formblatt zu Pauschalreisen nach § 651a BGB [PDF]</a></li>
              <li v-if="bookingRequestResponse.tourOperatorTOS"><a :href="bookingRequestResponse.tourOperatorTOS" target="_blank">Allgemeine Geschäftsbedingungen [PDF]</a></li>
            </ul>
            </template>

            <p>
              <b>Ihr Reiseveranstalter:</b><br>
                {{bookingRequestResponse.tourOperatorDetails.tourOperatorName}}<br>
                {{bookingRequestResponse.tourOperatorDetails.tourOperatorStreet}}<br>
                {{bookingRequestResponse.tourOperatorDetails.tourOperatorZipcode}} {{bookingRequestResponse.tourOperatorDetails.tourOperatorCity}}<br>
                {{bookingRequestResponse.tourOperatorDetails.tourOperatorCountry}}<br><br>
                Telefon: {{bookingRequestResponse.tourOperatorDetails.tourOperatorPhone}}<br>
                E-Mail: {{bookingRequestResponse.tourOperatorDetails.tourOperatorMail}}<br>
                Web: {{bookingRequestResponse.tourOperatorDetails.tourOperatorWebsite}}
            </p>
            <p>Bitte bestätigen Sie, dass Sie die o.g. PDF-Dokumente gelesen und den Allgemeinen Geschäftsbedingungen sowie den Reisebedingungen zustimmen.</p>
            <div>
              <div class="form-check form-switch mb-3">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="termsAccepted">
                <label class="form-check-label" for="flexSwitchCheckDefault">Ich bestätige, die AGB und Reisebedingungen gelesen zu haben und akzeptiere diese.</label>
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-success" :disabled="!termsAccepted" @click.prevent="doFinalBooking">JETZT VERBINDLICH BUCHEN</button>
          </div>
        </template>          
        <template v-else>
          <div class="booking-unsuccessful mb-5 p-5 border border-success">
            <h3 class="text-danger">Ihre Reise konnte leider nicht gebucht werden!</h3>
            <p>Aus technischen Gründen konnte Ihre Buchung leider nicht durchgeführt werden.</p>
            <p>Bitte versuchen Sie es erneut oder kontaktieren Sie uns gerne direkt telefonisch unter: +49 (0)211 - 8824 9621</p>
          </div>            
        </template>
      </div>
      <!-- BOOKING OVERVIEW AFTER SUCCESSFUL BOOKING REQUEST END -->


      <!-- FINAL BOOKING IS DONE -->
      <div v-if="finalBookingDone">
        <template v-if="finalBookingSuccessful">
          <div class="booking-successful mb-5 p-5 border border-success">
            <h3 class="text-success">Ihre Reise ist gebucht!</h3>
            <p>Ihre Reise wurde erfolgreich gebucht.</p>
            <p>Ihre Buchung wird unter der Buchungsnummer <b>{{finalBookingResponse.bookingId}}</b> bearbeitet.</p>
            <p>Bitte geben Sie diese Buchungsnummer bei allen Fragen rund um Ihre bevorstehende Reise an.</p>
            <h4>Wir wünschen Ihnen viel Freude bei Ihrer Reise!</h4>
          </div>
        </template>          
        <template v-else>
          <div class="booking-unsuccessful mb-5 p-5 border border-success">
            <h3 class="text-danger">Ihre Reise konnte leider nicht final gebucht werden!</h3>
            <p>Aus technischen Gründen konnte Ihre Buchung leider nicht durchgeführt werden.</p>
            <p>Bitte versuchen Sie es erneut oder kontaktieren Sie uns gerne direkt telefonisch unter: +49 (0)211 - 8824 9621</p>
          </div>            
        </template>
      </div>
      <!-- FINAL BOOKING IS DONE END -->


      <!-- BOOKING REQUEST IS NOT DONE OR WAS NOT SUCCESSFUL. FINAL BOOKING IS NOT DONE -->
      <div v-if="(!bookingRequestDone || (bookingRequestDone && !bookingRequestSuccessful)) && !finalBookingDone">
        
        <!-- BOOKING FORM -->
        <div v-if="!bookingInProgress">
          <form @submit.prevent="doBookingRequest">
            
            <template v-if="!processingUserData">
            <div class="form-area mb-5 p-3">
              <h3>Ihre Daten</h3>
              <div class="legend mb-3">
                <p>
                  Bitte geben Sie hier Ihre Kontaktdaten an.<br>
                  Alle mit <span class="required"></span> markierten Felder benötigen wir, um Ihnen einen perfekten Service bieten zu können.
                </p>                
              </div>
              <div class="form-fields">
                <div class="input-group mb-3">
                  <span class="input-group-text required">Anrede</span>
                  <select class="form-select" v-model="userData.title" id="registrant_title" required>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                  </select>
                  <template v-if="userData.title=='Divers'">
                    <span class="input-group-text required">Anrede für Reiseveranstalter*</span>
                    <select class="form-select" v-model="userData.birthTitle" id="registrant_birthtitle" required>
                        <option value="">- Bitte wählen Sie -</option>
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                    </select>
                  </template>
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text required">Name</span>
                  <input class="form-control" type="text" v-model="userData.firstname" id="registrant_firstname" placeholder="Vorname" required>
                  <input class="form-control" type="text" v-model="userData.lastname" id="registrant_lastname" placeholder="Nachname" required>
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text required">Anschrift</span>
                  <input class="form-control" type="text" v-model="userData.street" id="registrant_street" placeholder="Straße" required>
                  <input class="form-control" type="text" v-model="userData.streetnumber" id="registrant_streetnumber" placeholder="Hausnummer" required>
                  <input class="form-control" type="text" v-model="userData.zipcode" id="registrant_zipcode" placeholder="Plz" required>
                  <input class="form-control" type="text" v-model="userData.city" id="registrant_city" placeholder="Ort" required>                  
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text required">Rufnummer</span>
                  <div class="form-control">
                    <Multiselect v-model="userData.phone_prefix" id="registrant_phone_prefix" :options="allDialcodes" :groups="true" :searchable="true" :close-on-select="true" required />
                  </div>
                  <input class="form-control" type="text" pattern="[0-9]+" v-model="userData.phone" id="registrant_phone" placeholder="Telefonnummer" required>
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text">Mobilnummer</span>
                  <div class="form-control">
                    <Multiselect v-model="userData.mobile_prefix" id="registrant_mobile_prefix" :options="allDialcodes" :groups="true" :searchable="true" :close-on-select="true" />
                  </div>
                  <input class="form-control" type="text" pattern="[0-9]+" v-model="userData.mobile" id="registrant_mobile" placeholder="Mobilnummer">
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text required">E-Mail Adresse</span>
                  <input class="form-control" type="email" v-model="userData.email" id="registrant_email" placeholder="E-Mail Adresse" required>
                </div>                
              </div>
              <div class="errormsg" v-if="formErrors.userdata.length>0">
                <i class="fa fa-fw fa-exclamation-triangle text-danger"></i> <b>Bitte korrigieren Sie folgende Eingaben:</b>
                <ul>
                  <transition-group appear @before-enter="beforeEnterErrors" @enter="enterErrors">  
                  <li v-for="(msg, index) in formErrors.userdata" :key="msg" :data-index="index">{{msg}}</li>
                  </transition-group>
                </ul>
              </div>           
            </div>
            </template>
            <template v-else>
              <Spinner :size="'large'" />
            </template> 

            <div class="form-area mb-5 p-3">
              <h3>Reisende Personen</h3>
              <div class="legend mb-3">Bitte geben Sie hier die Daten der {{numTravelers}} reisenden Personen an.</div>
              
              <div v-for="(i, index) in numTravelers" :key="index" class="form-fields">
                <div class="input-group mb-3">
                  <span class="input-group-text w-auto required">{{index+1}}</span>
                  <select name="title" v-model="userData.travelers[index].title" :id="'traveler'+index+'_title'" class="form-select" required>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Kind">Kind</option>
                  </select>
                  <input class="form-control" type="text" v-model="userData.travelers[index].firstname" :id="'traveler'+index+'_firstname'" placeholder="Vorname" required>
                  <input class="form-control" type="text" v-model="userData.travelers[index].lastname" :id="'traveler'+index+'_lastname'" placeholder="Nachname" required>                 
                  <span class="input-group-text w-auto required">Geburtsdatum</span>
                  <input class="form-control" type="date" v-model="userData.travelers[index].birthday" :id="'traveler'+index+'_birthday'" placeholder="Vorname" min="1920-01-01" :max="todayDate" required>
                  <span class="input-group-text w-auto required">Nationalität</span>
                  <div class="form-control" style="min-width:250px">
                    <Multiselect v-model="userData.travelers[index].nationality" :id="'traveler'+index+'_nationality'" :options="allCountries" :groups="true" :searchable="true" :close-on-select="true" required />
                  </div>
                </div>            
              </div>
              <div class="errormsg" v-if="formErrors.travelers.length>0">
                <i class="fa fa-fw fa-exclamation-triangle text-danger"></i> <b>Bitte korrigieren Sie folgende Eingaben:</b>
                <ul>
                  <transition-group appear @before-enter="beforeEnterErrors" @enter="enterErrors">  
                  <li v-for="(msg, index) in formErrors.travelers" :key="msg" :data-index="index">{{msg}}</li>
                  </transition-group>
                </ul>
              </div>      
            </div>

            <div v-if="bookingData.chooseCabin==1" class="form-area mb-3 p-3">
              <h3>Kabine wählen</h3>
              <div class="legend mb-3">Bitte wählen Sie hier Ihre Wunsch-Kabine aus der Deck- und Kabinenübersicht.</div>
              <div class="row">
                <div class="col-4" style="border-right:1px solid var(--bs-primary)">
                  <div class="legend"><i class="fa fa-fw fa-layer-group"></i> Deck</div>
                  <div v-if="!checkingCabins">
                    <ul class="deck-list d-flex flex-wrap">
                      <transition-group appear @before-enter="beforeEnterCabins" @enter="enterCabins">
                      <template v-for="(deck, index) in availableCabins" :key="deck">
                        <li v-if="deck" @click="selectDeck(index)" :data-index="index" :class="{'active': selectedDeck==index}"><i class="fa fa-fw fa-layer-group"></i> Deck {{index}}</li>
                      </template>
                      </transition-group>
                    </ul>
                  </div>
                  <div v-else>
                    <div class="w-100 mx-auto"><Spinner /></div>
                  </div>  
                </div>
                <div class="col-8">
                  <div class="legend"><i class="fa fa-fw fa-kaaba"></i> Verfügbare Kabine(n)</div>
                  <div v-if="!checkingCabins">
                    <ul  class="cabins-list d-flex flex-wrap">
                      <transition-group appear @before-enter="beforeEnterCabins" @enter="enterCabins">
                      <template v-for="(deck, index) in availableCabins" :key="deck">
                        <template v-if="index==selectedDeck">
                          <template v-for="(cabin, cabindex) in deck" :key="cabin">
                            <li @click="selectCabin(selectedDeck, cabin)" :data-index="cabindex" :class="{'active': selectedCabin==selectedDeck+''+cabin}"><i class="fa fa-fw fa-kaaba"></i> Kabine {{selectedDeck}}{{cabin}}</li>
                          </template>
                        </template>
                      </template>
                      </transition-group>
                    </ul>
                  </div>
                  <div v-else>
                    <div class="w-100 mx-auto"><Spinner /></div>
                  </div>  
                </div>
              </div>
              <div class="errormsg" v-if="formErrors.cabin.length>0">
                <i class="fa fa-fw fa-exclamation-triangle text-danger"></i> <b>Bitte korrigieren Sie folgende Eingaben:</b>
                <ul>
                  <transition-group appear @before-enter="beforeEnterErrors" @enter="enterErrors">  
                  <li v-for="(msg, index) in formErrors.cabin" :key="index" :data-index="index" v-html="msg"></li>
                  </transition-group>
                </ul>
              </div>
            </div>  
            <div v-else class="form-area mb-3 p-3">
              <h3>Kabine</h3>
              <div class="legend">Ihre Kabine wird Ihnen zugewiesen.</div>
            </div>        

            <div class="form-submit mb-5 p-3">
              <div class="d-block d-md-flex justify-content-end">              
                <div class="form-submit">
                  <button type="submit" class="btn btn-lg btn-success" :disabled="!btnSubmitActive">Daten überprüfen &amp; Weiter</button>
                </div>
              </div>            
            </div>

          </form>
        </div>
        <!-- BOOKING FORM END -->

        <div v-else>
            <Spinner />
        </div>
      </div>
      <!-- BOOKING REQUEST IS NOT DONE OR WAS NOT SUCCESSFUL. FINAL BOOKING IS NOT DONE END -->

  </div>
</template>  

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { inject, onBeforeMount, onMounted, onUpdated } from '@vue/runtime-core';
import gsap from 'gsap';
//Components
import Spinner from '../Spinner.vue';
import RouteDetails from '../CruiseDetails/RouteDetails.vue';
import Multiselect from '@vueform/multiselect';
//Composables
import useBookingAPI from '../../composables/useBookingAPI';
import helperFunctions from '../../composables/helperFunctions';
import useMetaAPI from '../../composables/useMetaAPI';
import useLoginAPI from '../../composables/useLoginAPI';

export default {
  props: ['hanseat'],
  components: {Spinner, RouteDetails, Multiselect},
  setup(props, context) {

    const lang = inject('lang');
    const JWTToken = ref(inject('JWTToken'));
    const user = ref(inject('user'));
    const AccessToken = ref(inject('AccessToken'));

    const hanseat = ref(props.hanseat);

    const error = ref('');
    const bookingInProgress = ref(false);

    const router = useRouter();

    const userData = ref({});
    const userAddresses = ref([]);
    const bookingData = ref({});
    const numTravelers = ref(0);

    const { getYMDDate, setUserdataToSessionStorage, getUserDataFromSessionStorage, getBookingData, setBookingData, getCalcCriteria, validateEmail } = helperFunctions();
    const { getAddresses } = useLoginAPI();

    const calcCriteria = ref(getCalcCriteria());

    const todayDate = getYMDDate(new Date());

    const { getCountries } = useMetaAPI();
    const allCountries = ref([]);
    const allDialcodes = ref([]);
    const fetchingCountries = ref(false);

    const { BookingApiError, getCabins, sendBookingRequest } = useBookingAPI();
    const checkingCabins = ref(false);
    const availableCabins = ref([]);
    const selectedDeck = ref('');
    const selectedCabin = ref('');

    const formErrors = ref({userdata:[], travelers:[], cabin: []});
    const btnSubmitActive = ref(false);

    const bookingRequestDone = ref(false);
    const bookingRequestSuccessful = ref(false);
    const bookingRequestResponse = ref({});

    const finalBookingDone = ref(false);
    const finalBookingSuccessful = ref(false);
    const finalBookingResponse = ref({});

    const processingUserData = ref(false);

    const termsAccepted = ref(false);


    onBeforeMount(() => {
      bookingData.value = getBookingData();
      if(bookingData.value == false) {
        let errorMessage = 'Ihre Option auf diese Reise ist leider abgelaufen.<br><br>Bitte wählen Sie sie erneut aus und führen Sie die Buchung erneut durch.';
        router.push({name:'Error', params: {errorMessage: errorMessage}});
      }

      selectedCabin.value = bookingData.value.selectedCabin;

      numTravelers.value = parseInt(calcCriteria.value.adults) + parseInt(calcCriteria.value.children);

      userData.value = { 
        title: 'Herr',
        birthTitle: '',
        firstname: '',
        lastname: '',
        street: '',
        streetnumber: '',
        zipcode: '',
        city: '', 
        country: 'DE',
        phone: '',
        phone_prefix: '+49',
        mobile: '',
        mobile_prefix: '+49',
        email: user.email,
        primary_address_id: 0,
        travelers: Array()
        };

      
      if(AccessToken.value) { //for logged in users
        processingUserData.value = true;
        getAddresses(AccessToken.value)
          .then((result) => {
            userAddresses.value = result;

            if(userAddresses.value.length > 0) {
              userAddresses.value.forEach((addr, index) => {
                if(addr.is_primary == 1 & addr.is_enabled) {
                  //Primary address existing
                  userData.value.title = addr.title,
                  userData.value.firstname = addr.first_name;
                  userData.value.lastname = addr.last_name;
                  userData.value.primary_address_id = addr.address_id;
                  userData.value.street = addr.street;
                  userData.value.streetnumber = addr.street_number;
                  userData.value.zipcode = addr.zipcode;
                  userData.value.city = addr.city;
                  userData.value.country = addr.country;
                  if(typeof addr.phone !== 'undefined') userData.value.phone_prefix = addr.phone.substring(0, addr.phone.indexOf('-'));
                  if(typeof addr.phone !== 'undefined') userData.value.phone = addr.phone.substring(addr.phone.indexOf('-')+1);
                  if(typeof addr.mobile !== 'undefined') userData.value.mobile_prefix = addr.mobile.substring(0, addr.mobile.indexOf('-'));
                  if(typeof addr.mobile !== 'undefined') userData.value.mobile = addr.mobile.substring(addr.mobile.indexOf('-')+1);
                  if(typeof addr.email !== 'undefined') userData.value.email = addr.email;                  
                }
              });
            }
        
            processingUserData.value = false;
          })
          .catch((err) => { 
            console.log(err);
            error.value += LoginApiError.value.message; 
        
            processingUserData.value = false; 
          });    
      } else {
        //for not logged in users thay may have data in the session storage
        userData.value = getUserDataFromSessionStorage();
      }

      
      for(let x = 0; x<=numTravelers.value-1; x++) {
        if(typeof userData.value === 'undefined') { 
          userData.value.travelers[x] = {title: 'Herr', firstname: '', lastname: '', birthday: ''}; 
        } else {
          if(typeof userData.value.travelers[x] === 'undefined') userData.value.travelers[x] = {title: 'Herr', firstname: '', lastname: '', birthday: ''};
        }
      }      


      if(bookingData.value.chooseCabin==1) checkCabins();

      getAllCountries();
    });

    onUpdated(() => {
      //userData.value.travelers = travelers.value;
      validateFormData();
    });

    //! This whole function block can be replaced with getCountriesAndDialCodes() as seen in DashboardUserdata.vue
    const getAllCountries = () => {
      if(JWTToken.value !== null) {
        fetchingCountries.value = true;
        let regions = [
          {
            label: 'Am häufigsten gewählt',
            options: [{value: 'DE', label: 'Deutschland'}, {value: 'AT', label: 'Österreich'}, {value: 'CH', label: 'Schweiz'}]
          },
          {
            label: 'Alle Nationalitäten',
            options: []
          }
        ]; 
        let dialCodes = [
          {
            label: 'Am häufigsten gewählt',
            options: [{value: '+49', label: '+49 - Deutschland'}, {value: '+43', label: '+43 - Österreich'}, {value: '+41', label: '+41 - Schweiz'}]          
          },
          {
            label: 'Alle Landesvorwahlen',
            options: []
          }
        ];
        getCountries(JWTToken.value)
          .then((result) => {
            if(result) {
              // allCountries.value = result.countries; For simple select form element
              result.countries.forEach((key, index) => {
                if(key.countryId > 0) {
                  regions[1].options.push({value: key.alpha2Code, label: key.nameCommon});
                  dialCodes[1].options.push({value: '+'+key.dialcode, label: '+'+key.dialcode+' - '+key.nameCommon});
                }
              }) 
              allCountries.value = regions;       
              allDialcodes.value = dialCodes;
              // console.log(allCountries.value);        
            } else {
              console.log('Konnte keine Länderliste abrufen.');
            }
          })
          .catch((err) => { console.log(err), error.value += BookingApiError })
          .finally(() => { 
            fetchingCountries.value = false;
          });
      } else {
        router.push({name:'Home'});
      }
    };

    const validateFormData = () => {
      formErrors.value.userdata = [];
      if(userData.value.firstname.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Vornamen an.');
      if(userData.value.lastname.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Nachnamen an.');
      if(userData.value.title!='Herr' && userData.value.title!='Frau' &&userData.value.title!='Divers') formErrors.value.userdata.push('Bitte wählen Sie eine Anrede aus.');
      if(userData.value.title=='Divers' && (userData.value.birthTitle=='' || typeof userData.value.birthTitle == 'undefined')) formErrors.value.userdata.push('Bitte wählen Sie eine Anrede aus, die wir an den Reiseveranstaler weitergeben dürfen.<br>* Die eingeschränkte Wahl zwischen "Herr" und "Frau" geht auf die Reiseveranstalter zurück.');
      if(userData.value.street.length<3) formErrors.value.userdata.push('Bitte geben Sie den Straßennamen Ihrer Wohnanschrift an.');
      if(userData.value.streetnumber.length<1) formErrors.value.userdata.push('Bitte geben Sie die Hausnummer Ihrer Wohnanschrift an.');
      if(userData.value.zipcode.length<5) formErrors.value.userdata.push('Bitte geben Sie die Postleitzahl Ihrer Wohnanschrift an.');
      if(userData.value.city.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Wohnort an.');
      if(userData.value.phone.length<9) formErrors.value.userdata.push('Bitte geben Sie Ihre Telefonnummer an, unter der wir Sie zu Zwecken der Reisebuchung kontaktieren dürfen.');
      if(!validateEmail(userData.value.email)) formErrors.value.userdata.push('Bitte geben Sie Ihre E-Mail Adresse an, unter der wir Sie zu Zwecken der Reisebuchung kontaktieren dürfen.');
      formErrors.value.travelers = [];
      if(userData.value.travelers[0].firstname.length<3 || userData.value.travelers[0].lastname.length<3 || userData.value.travelers[0].birthday=='' || userData.value.travelers[0].nationality==null) formErrors.value.travelers.push('Bitte geben Sie die Daten der ersten reisenden Person vollständig an.');
      if(numTravelers.value > 1) if(userData.value.travelers[1].firstname.length<3 || userData.value.travelers[1].lastname.length<3 || userData.value.travelers[1].birthday=='' || userData.value.travelers[1].nationality==null) formErrors.value.travelers.push('Bitte geben Sie die Daten der zweiten reisenden Person vollständig an.');
      if(numTravelers.value > 2) if(userData.value.travelers[2].firstname.length<3 || userData.value.travelers[2].lastname.length<3 || userData.value.travelers[2].birthday=='' || userData.value.travelers[1].nationality==null) formErrors.value.travelers.push('Bitte geben Sie die Daten der dritten reisenden Person vollständig an.');
      if(numTravelers.value > 3) if(userData.value.travelers[3].firstname.length<3 || userData.value.travelers[3].lastname.length<3 || userData.value.travelers[3].birthday=='' || userData.value.travelers[1].nationality==null) formErrors.value.travelers.push('Bitte geben Sie die Daten der vierten reisenden Person vollständig an.');
      if(numTravelers.value > 4) if(userData.value.travelers[4].firstname.length<3 || userData.value.travelers[4].lastname.length<3 || userData.value.travelers[4].birthday=='' || userData.value.travelers[1].nationality==null) formErrors.value.travelers.push('Bitte geben Sie die Daten der fünften reisenden Person vollständig an.');
      formErrors.value.cabin = [];
      if(bookingData.value.chooseCabin==1 && selectedCabin.value=='') formErrors.value.cabin.push('Bitte wählen Sie Ihre Kabine aus.');

      if(formErrors.value.userdata.length==0 && formErrors.value.travelers.length==0 && formErrors.value.cabin == 0) btnSubmitActive.value = true;
      else btnSubmitActive.value = false;
    };

    const checkCabins = () => {
      if(JWTToken.value !== null) {
        console.log('Checking available cabins...', bookingData.value.oid);
        checkingCabins.value = true;
        getCabins(bookingData.value.oid, JWTToken.value)
          .then((result) => {
            // console.log(result);
            if(result) {
              let x = 0;
              var preselectedCabinStillAvailable = false; //if there is an already selected cabin number in bookingData, we have to check whether it is still bookable or not. If not, we will set selectedCabin.value = '' so the user has to select a new cabin
              result.cabins.forEach((cabin) => {
                //Create array of cabins, grouped by decks [deckno] = [cabinno1, cabinno2, cabinno3, ...]
                let cabdeck = cabin.number.substring(0, cabin.number.length -3);
                let cabnumber = cabin.number.substring(cabin.number.length -3);
                if(typeof availableCabins.value[cabdeck] === 'undefined') availableCabins.value[cabdeck] = [];
                availableCabins.value[cabdeck].push(cabnumber);
                if(selectedCabin.value == (cabdeck+''+cabnumber)) preselectedCabinStillAvailable = true;
                if(x==0) selectedDeck.value = cabdeck;
                x++;
              });
              if(!preselectedCabinStillAvailable) selectedCabin.value = '';
            } else {
              console.log('Konnte keine freien Kabinen abrufen.');
            }
          })
          .catch((err) => { console.log(err), error.value += BookingApiError })
          .finally(() => { 
            checkingCabins.value = false;
          });
      } else {
        router.push({name:'Home'});
      }
    };    

    const selectDeck = (deckNumber) => {
      selectedDeck.value = deckNumber;
      selectedCabin.value = '';
    }    

    const selectCabin = (deckNumber, cabinNumber) => {
      let cabNo = deckNumber+''+cabinNumber;
      selectedCabin.value = cabNo;
      bookingData.value.selectedCabin = cabNo;
      setBookingData(bookingData.value);
      validateFormData();
    }


    const doBookingRequest = () => {
      //Send Booking Request (Buchungsanfrage)
      setUserdataToSessionStorage(userData.value);
      
      if(hanseat.value == 1) {
        bookingRequestResponse.value = {
          tourOperatorName: "Hanseat Reisen GmbH",
          tourOperatorStreet: "Langenstraße 20",
          tourOperatorZipcode: "28195",
          tourOperatorCity: "Bremen",
          tourOperatorCountry: "Deutschland",
          tourOperatorMail: "info@hanseatreisen.de",
          tourOperatorPhone: "+49 (421) 160 56 8780",
          tourOperatorWebsite: "https://www.hanseatreisen.de",
          travelRequirements: {}
        };
        bookingRequestDone.value = true;
        bookingRequestSuccessful.value = true;        
      } else {

        if(JWTToken.value !== null) {
          context.emit('blockingOn');
          console.log('Sending booking request...', bookingData.value.oid);
          bookingInProgress.value = true;
          let action = 'BR';
          let cabinno = (bookingData.value.chooseCabin == 1) ? selectedCabin.value : '-1';

          let registrant = userData.value;

          let travelers = [];
          for(let x=0; x<= numTravelers.value-1; x++) {
            travelers.push(userData.value.travelers[x]);
          }
          sendBookingRequest(bookingData.value, action, cabinno, registrant, travelers, JWTToken.value, user.value)
            .then((result) => {
              if(result.success == true) {
                //Booking process done and booking was successful :)
                bookingRequestResponse.value = result;
                bookingRequestDone.value = true;
                bookingRequestSuccessful.value = true;
              } else {
                //Booking process done but booking was not successful
                bookingRequestDone.value = true;
                bookingRequestSuccessful.value = false;
              }
            })
            .catch((err) => { console.log(err), error.value += BookingApiError })
            .finally(() => { 
              bookingInProgress.value = false;
              context.emit('blockingOff');
            });            
        } else {
          console.log('JWT Token expired or not existent.');
        }
      }
    };

    const doFinalBooking = () => {
      //Send final Booking Request (Buchung)
      if(JWTToken.value !== null) {
        context.emit('blockingOn');
        console.log('Do final booking...', bookingData.value.oid);
        bookingInProgress.value = true;
        let action = 'BR';  // ========================================================================= NEEDS TO BE SET TO "B" FOR FINAL RELEASE !!!!!!!!!
        let cabinno = (bookingData.value.chooseCabin == 1) ? selectedCabin.value : '-1';
        
        let registrant = userData.value;
        
        let travelers = [];
        for(let x=0; x<= numTravelers.value-1; x++) {
          travelers.push(userData.value.travelers[x]);
        }

        if(hanseat.value == 1) {
          
        }

        sendBookingRequest(bookingData.value, action, cabinno, registrant, travelers, JWTToken.value, user.value)
          .then((result) => {
            console.log('RESULT', result);
            if(result.success == true) {
              //Booking process done and booking was successful :)
              finalBookingResponse.value = result;
              finalBookingDone.value = true;
              finalBookingSuccessful.value = true;

            } else {
              //Booking process done but booking was not successful
              finalBookingDone.value = true;
              finalBookingSuccessful.value = false;
            }
          })
          .catch((err) => { console.log(err), error.value += BookingApiError })
          .finally(() => { 
            bookingInProgress.value = false;
            context.emit('blockingOff');
          });            
      } else {
        console.log('JWT Token expired or not existent.');
      }
    };

    /*
    Animations 
    */
    const beforeEnterCabins = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateY(50px)';
      el.style.backgroundColor = '#2777bd';
    }
    const enterCabins = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        backgroundColor: 'transparent',
        duration: 0.05,
        onComplete: done,
        delay: el.dataset.index * 0.05 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }    

    const beforeEnterErrors = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateX(50px)';
    }
    const enterErrors = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.2,
        onComplete: done,
        delay: el.dataset.index * 0.2 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }       

    return {
      error, doBookingRequest, bookingInProgress, processingUserData,
      hanseat,
      userData, bookingData, calcCriteria,
      numTravelers, todayDate,
      checkingCabins, availableCabins, 
      fetchingCountries, allCountries, allDialcodes,
      selectDeck, selectedDeck, selectCabin, selectedCabin, 
      beforeEnterCabins, enterCabins, beforeEnterErrors, enterErrors,
      formErrors, btnSubmitActive, 
      bookingRequestDone, bookingRequestSuccessful, bookingRequestResponse, termsAccepted, 
      doFinalBooking, finalBookingSuccessful, finalBookingDone, finalBookingResponse
      };
  }
}
</script>

<style scoped>
  .deck-list,
  .cabins-list {
    list-style-type: none;
    padding: 0;
  }
  .deck-list li,
  .cabins-list li {
    padding: 5px 10px;
    border: 1px solid var(--bs-primary);
    background: rgba(var(--bs-primary-rgb),.1);
    color: var(--bs-primary);
    border-radius: 10px;
    margin: 5px 5px 0 0;
    cursor: pointer;
  }
  .deck-list li.active,
  .cabins-list li.active { 
    background: var(--bs-success) !important;
    border-color: var(--bs-success-border) !important;
    color: #fff !important;
  }
  .deck-list li:hover,
  .cabins-list li:hover {
    background: var(--bs-primary-hover) !important;
    border-color: var(--bs-primary) !important;
    color: #fff !important;
  }
  .booking-successful {
    background: rgba(var(--bs-success-rgb),.25);
    border-radius: 10px;
  }
  .booking-unsuccessful {
    background: rgba(var(--bs-danger-rgb),.25);
    border-radius: 10px;
  }  
</style>