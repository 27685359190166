import { ref } from 'vue';
import useMetaAPI from './useMetaAPI';

const { MetaApiError, getCountries } = useMetaAPI();

const createFormFieldsError = ref(null);
const fetchingFormFields = ref(false);

const getCountriesAndDialCodes = (JWTToken) => {
  if(JWTToken !== null) {
    fetchingFormFields.value = true;
    let regions = [
      {
        label: 'Am häufigsten gewählt',
        options: [{value: 'DE', label: 'Deutschland'}, {value: 'AT', label: 'Österreich'}, {value: 'CH', label: 'Schweiz'}]
      },
      {
        label: 'Alle Nationalitäten',
        options: []
      }
    ]; 
    let dialCodes = [
      {
        label: 'Am häufigsten gewählt',
        options: [{value: '+49', label: '+49 - Deutschland'}, {value: '+43', label: '+43 - Österreich'}, {value: '+41', label: '+41 - Schweiz'}]          
      },
      {
        label: 'Alle Landesvorwahlen',
        options: []
      }
    ];
    getCountries(JWTToken)
      .then((result) => {
        if(result) {
          result.countries.forEach((key, index) => {
            if(key.countryId > 0) {
              regions[1].options.push({value: key.alpha2Code, label: key.nameCommon});
              dialCodes[1].options.push({value: '+'+key.dialcode, label: '+'+key.dialcode+' - '+key.nameCommon});
            }
          }) 
        } else {
          console.log('Konnte keine Länderliste abrufen.');
          createFormFieldsError.value = 'Countries list could not be fetched.';
        }
      })
      .catch((err) => { 
        console.log(err);
        createFormFieldsError.value = err;
      })
      .finally(() => { 
        fetchingFormFields.value = false;
      });
      return { countries: regions, dialCodes: dialCodes }
  } else {
    createFormFieldsError.value = 'JWTToken not passed or invalid.';
  }
};  

const createFormFields = () => {
  return  { createFormFieldsError, fetchingFormFields, getCountriesAndDialCodes };
}

export default createFormFields;