<template>
    <div class="container">        
        <div class="ccontainer">
          imprint dummy
        </div>        
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>