import { ref } from "vue";
import axios from "axios";
import errorHandling from "./errorHandling";
import helperFunctions from "./helperFunctions";

const { parseAPIErrorCodes } = errorHandling();
const { setUserdataToSessionStorage } = helperFunctions();

const LoginApiError = ref(null);

const loginAPIUrl = process.env.VUE_APP_SEEREISEN_DE_LOGIN_API;

const getAllUserData = async (firebaseAccessToken, storeToSessionStorage=true) => {
  LoginApiError.value = null;
  let user = null;
  let addresses = null;
  let wishlist = null;
  await getUser(firebaseAccessToken)
    .then((result) => {
      user = result;
    });
  await getAddresses(firebaseAccessToken)
    .then((result) => {
      addresses = result;
    });
  await getWishlist(firebaseAccessToken)
    .then((result) => {
      wishlist = result;
    });
  
  if(storeToSessionStorage) {
    //Store user data to session storage
    let userData = { 
      title: 'Herr',
      birthTitle: '',
      firstname: '',
      lastname: '',
      street: '',
      streetnumber: '',
      zipcode: '',
      city: '', 
      country: 'DE',
      phone: '',
      phone_prefix: '+49',
      mobile: '',
      mobile_prefix: '+49',
      email: (user) ? user.users[0].email : '',
      email_login: (user) ? user.users[0].email : '',
      primary_address_id: 0,
      travelers: Array()
      };

    if(addresses.length > 0) {
      addresses.forEach((addr, index) => {
        if(addr.is_primary == 1 & addr.is_enabled) {
          //Primary address existing
          userData.title = addr.title,
          userData.firstname = addr.first_name;
          userData.lastname = addr.last_name;
          userData.street = addr.street;
          userData.streetnumber = addr.street_number;
          userData.zipcode = addr.zipcode;
          userData.city = addr.city;
          userData.country = addr.country;
          if(typeof addr.phone !== 'undefined') userData.phone = addr.phone;
          if(typeof addr.mobile !== 'undefined') userData.mobile = addr.mobile;
          if(typeof addr.email !== 'undefined') userData.email = addr.email;
          userData.primary_address_id = addr.address_id;
          userData.createdAt = addr.created_at;
          userData.lastUpdate = addr.updated_at;
        }
      });

      setUserdataToSessionStorage(userData);
    }      
  }

  return {
    user: user,
    addresses: addresses,
    wishlist: wishlist
  }
}

const getUser = async (firebaseAccessToken) => {
  LoginApiError.value = null;
  
  let endpoint = 'users';
  
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.get(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der User-Details (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;  
}

const getAddresses = async (firebaseAccessToken) => {
  LoginApiError.value = null;
  
  let endpoint = 'addresses';
  
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.get(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der User-Addresses (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const updateAddress = async (firebaseAccessToken, updateId, userData, addressType=1, isPrimary=1, isEnabled=1) => {
  LoginApiError.value = null;
  
  let endpoint = 'addresses';
  endpoint = endpoint.concat('/').concat(updateId);  //addresses/1

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }  

  let addressParams = {
    title: userData.title,
    first_name: userData.firstname,
    last_name: userData.lastname,
    street: userData.street,
    street_number: userData.streetnumber,
    zipcode: userData.zipcode,
    city: userData.city,
    country: userData.country,
    phone: userData.phone_prefix+'-'+userData.phone,
    mobile: userData.phone_prefix+'-'+userData.mobile,
    email: userData.email,
    address_type: addressType,
    is_primary: isPrimary,
    is_enabled: isEnabled
  };

  try {
    const response = await axios.put(loginAPIUrl.concat(endpoint), addressParams, apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Update der User-Addresse (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const addAddress = async (firebaseAccessToken, userData, addressType=1, isPrimary=1, isEnabled=1) => {
  LoginApiError.value = null;
  
  let endpoint = 'addresses';

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }  

  let addressParams = {
    title: userData.title,
    first_name: userData.firstname,
    last_name: userData.lastname,
    street: userData.street,
    street_number: userData.streetnumber,
    zipcode: userData.zipcode,
    city: userData.city,
    country: userData.country,
    phone: userData.phone_prefix+'-'+userData.phone,
    mobile: userData.phone_prefix+'-'+userData.mobile,
    email: userData.email,    
    address_type: addressType,
    is_primary: isPrimary,
    is_enabled: isEnabled
  };

  try {
    const response = await axios.post(loginAPIUrl.concat(endpoint), addressParams, apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Anlegen der User-Addresse (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const deleteAddress = async (firebaseAccessToken, addressId) => {
  LoginApiError.value = null;
  
  let endpoint = 'addresses';
  endpoint = endpoint.concat('/').concat(addressId);

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.delete(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Löschen der User-Addresses (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const getWishlist = async (firebaseAccessToken) => {
  LoginApiError.value = null;
  
  let endpoint = 'wishlists';
  
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.get(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Wishlist (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const addToWishlist = async (firebaseAccessToken, uniqueTravelId) => {
  LoginApiError.value = null;
  
  let endpoint = 'wishlists';
  endpoint = endpoint.concat('/').concat(uniqueTravelId);

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }  

  let wishlistParams = {};

  try {
    const response = await axios.post(loginAPIUrl.concat(endpoint), wishlistParams, apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Anlegen der Cruise in der Wishlist (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const deleteFromWishlist = async (firebaseAccessToken, wishId) => {
  LoginApiError.value = null;
  
  let endpoint = 'wishlists';
  endpoint = endpoint.concat('/').concat(wishId);

  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.delete(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Löschen der Cruise von der Wishlist (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}

const getBookings = async (firebaseAccessToken) => {
  LoginApiError.value = null;
  
  let endpoint = 'bookings';
  
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+firebaseAccessToken
    }
  }

  try {
    const response = await axios.get(loginAPIUrl.concat(endpoint), apiRequestConfig);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == '') {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Bookings (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    

  } catch(err) {
    LoginApiError.value = {message: err.message, userMessage: parseAPIErrorCodes(err.message, endpoint)};
    console.log(LoginApiError.value.message);
  }
  return false;    
}


const useLoginAPI = () => {
  return  { 
    LoginApiError, 
    getAllUserData, getUser, getAddresses, updateAddress, addAddress, deleteAddress,
    getWishlist, addToWishlist, deleteFromWishlist,
    getBookings
  };
}

export default useLoginAPI;