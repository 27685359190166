<template>
  <div class="cruise-titleimage position-relative overflow-hidden p-2 rounded">
    <div class="blurred" :style="{'background-image': 'url('+cruiseTitleimageSrc+')'}" style="background-position:center center;background-repeat:no-repeat;background-size:cover;filter:blur(10px);opacity:.75;position:absolute;top:0;left:0;right:0;bottom:0;display:block"></div>
    <img class="titleimage border mx-auto d-block position-relative" :src="cruiseTitleimageSrc" alt="bild" >
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
export default {
  props: ['cruiseTitleimageSrc'],
  setup(props) {
    const cruiseTitleimageSrc = ref('');
    
    onMounted(() => {
      cruiseTitleimageSrc.value = props.cruiseTitleimageSrc;
    });

    return { cruiseTitleimageSrc };
  }
}
</script>

<style>

</style>