<template>
  <div class="cabins-filter">
    <div>
      <p>Bitte wählen Sie alle für Sie infrage kommenden Kabinen- und Preismodelle aus.</p>
    </div>
    
    <div>
      <h5>Kabinentypen:</h5>
      <div class="input-group d-flex flex-wrap justify-content-start">
        <div v-if="showFilters.includes(1)" class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_ct1" :checked="showCabinTypes.includes(1)" @change="filterCabins(1, null)">
          <label class="form-check-label" for="filter_ct1">Innenliegende Kabinen</label>
        </div>
        <div v-if="showFilters.includes(2)" class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_ct2" :checked="showCabinTypes.includes(2)" @change="filterCabins(2, null)">
          <label class="form-check-label" for="filter_ct2">Außenliegende Kabinen</label>
        </div>      
        <div v-if="showFilters.includes(3)" class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_ct3" :checked="showCabinTypes.includes(3)" @change="filterCabins(3, null)">
          <label class="form-check-label" for="filter_ct3">Kabinen mit Balkon</label>
        </div>     
        <div v-if="showFilters.includes(4)" class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_ct4" :checked="showCabinTypes.includes(4)" @change="filterCabins(4, null)">
          <label class="form-check-label" for="filter_ct4">Suiten</label>
        </div>  
      </div>

      <template v-if="cruisePricesWithFlightAvailable">
      <h5>Preismodelle:</h5>
      <div class="input-group d-flex justify-content-start">
        <div class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_withoutflight" :checked="showPriceTypes.includes('woflight')" @change="filterCabins( null, 'woflight')">
          <label class="form-check-label" for="filter_withoutflight">Preise ohne Flug</label>
        </div>  
        <div class="form-check mx-2">
          <input class="form-check-input" type="checkbox" id="filter_withflight" :checked="showPriceTypes.includes('wflight')" @change="filterCabins(null, 'wflight')">
          <label class="form-check-label" for="filter_withflight">Preise inklusive Flug</label>
        </div>
      </div>
      </template>

    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { inject, onBeforeMount, onMounted } from '@vue/runtime-core';
export default {
  props: ['cruiseOnlyPricesAvailable', 'cruisePricesWithFlightAvailable'],
  setup(props, context) {

    const cruiseOnlyPricesAvailable = ref(props.cruiseOnlyPricesAvailable);
    const cruisePricesWithFlightAvailable = ref(props.cruisePricesWithFlightAvailable);

    const showCabinTypes = inject('showCabinTypes'); 
    const showPriceTypes = inject('showPriceTypes');
    const showFilters = ref([]);

    const filterCabins = (cabinType, priceType) => {
      context.emit('emitFilterCabins', cabinType, priceType);
    }
    

    onBeforeMount(() => {
      showFilters.value = showCabinTypes.value; //Initially all available cabinTypes are set in showCabinTypes Array. But since it is reactive and will change, when the user
      //toggles through filters, we have to get a copy of it before the users will change it
    });


    return { cruiseOnlyPricesAvailable, cruisePricesWithFlightAvailable, showCabinTypes, showPriceTypes, showFilters, filterCabins };
  }
}
</script>

<style>

</style>