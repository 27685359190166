<template>
  <div class="dashboard-pricefinder">
    <h3>Preisfinder</h3>
    <p class="introtext">
      In diesem Bereich können Sie eine Suche nach einer bestimmten Reise zu Ihrem bestimmten Wunschpreis speichern. 
      Sobald diese Reise zu Ihrem Wunschpreis angeboten wird, erhalten Sie eine Benachrichtigung.
    </p>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>