<template>
  <div class="dashboard-leaflet">
    <h3>Merkzettel</h3>
    <p class="introtext">
      Nachfolgend sehen Sie alle Reifen aufgelistet, die sich derzeit auf Ihrem Merkzettel befinden.<br>
      Markieren Sie bis zu 5 Reisen, die Sie miteinander vergleichen möchten.
    </p>
    <div class="error" v-if="error!=''">{{ error }}</div>
    <div v-if="cruisesRemoved.length>0">
      <p class="text-danger">
        {{cruisesRemoved.length}} Reise{{(cruisesRemoved.length>1)?'n':''}} wurden von Ihrem Merkzettel entfernt, da diese zum gewählten Reisetermin nicht mehr verfügbar {{(cruisesRemoved.length>1)?'sind':'ist'}}.
      </p>
    </div>

    <div class="leaflet-cruises">
      <transition appear>
      <div v-if="JWTToken && wishlist.length > 0" class="">
        <div class="d-flex justify-content-end">
          <div class="px-3">
            Alle auswählen <input type="checkbox" @change="toggleCheckAll('', $event)">
          </div>
        </div>
        <template v-for="(cruise, index) in wishlist" :key="index">
          <CruiseSummary class="mb-3"
            :uniqueTravelId="cruise.unique_tcc_travel_id" 
            :wishId="cruise.wish_id"
            :showRouteDetailsButton="true" 
            :showRoute="true" 
            :routeToggleable="true" 
            :routeCollapsed="true" 
            :selectableForCompare="true"  
            @summaryUnavailable="cruiseSummaryUnavailable" 
            @updateSelectedCruises="updateSelectedCruises"
            @error="handleLeafletError"
            />
        </template>
      </div>
      </transition>
      <hr>
      <div class="d-flex justify-content-between mb-3">
        <div class="fw-bold">Ausgewählte Reisen</div>
        <div>
          <button class="btn btn-lg btn-danger mx-1" @click.prevent="deleteCruises" :disabled="!deleteButtonEnabled">Vom Merkzettel löschen</button>
          <button class="btn btn-lg btn-success mx-1" @click.prevent="compareCruises" :disabled="!compareButtonEnabled">Vergleichen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { useRouter } from 'vue-router';
import { inject, onMounted, onUpdated, ref } from '@vue/runtime-core';
import store from '../../store/store';
//Composables
import useLoginAPI from '../../composables/useLoginAPI';
import helperFunctions from '../../composables/helperFunctions';
//Components
import Spinner from '../Spinner.vue';
import CruiseSummary from '../Summaries/CruiseSummary.vue';


export default {
  components: {Spinner, CruiseSummary},
  setup(props, context) {

    const JWTToken = ref(store.getters.getJWTToken);
    const user = store.getters.getUser;
    const AccessToken = store.getters.getAccessToken;

    const error = ref('');
    const router = useRouter();   

    const { getLeafletData, removeCruiseFromLeaflet } = helperFunctions();
    const { getWishlist, deleteFromWishlist } = useLoginAPI();

    const wishlist = ref([]);

    const selectedCruises = ref([]);
    const compareButtonEnabled = ref(false);
    const deleteButtonEnabled = ref(false);

    const cruisesRemoved = ref([]);

    onMounted(() => {
      console.log(user);
      if(user && !user.isAnonymous) {
        //For logged in users we fetch the wishlist/leaflet from the Seereisen-API
        getWishlist(AccessToken)
          .then((result) => {
            wishlist.value = result;
          });
      } else {
        //For non-logged-in users (anonymous users) we fetch the wishlist from the session storage
        wishlist.value = getLeafletData();
        if(wishlist.value !== null && wishlist.value.length > 0) {
          wishlist.value.forEach((val, index) => {
            wishlist.value[index] = {unique_tcc_travel_id : wishlist.value[index]}
          });
        }
      }
    });

    onUpdated(() => {

    })

    const cruiseSummaryUnavailable = (uniqueTravelId) => {
      //If the cruise summary Components emits the info that the cruise could not be loaded, we will drop it from the leaflet
      console.log('Deleted Cruise '+uniqueTravelId+' from leaflet because it is unavailable.');
      cruisesRemoved.value.push(uniqueTravelId);
      removeCruiseFromLeaflet(uniqueTravelId);
    };

    const updateSelectedCruises = () => {
      selectedCruises.value = [];
      compareButtonEnabled.value = false;
      deleteButtonEnabled.value = false;
      document.getElementsByName('compare-cruise').forEach((key, index) => {
        if(key.checked) selectedCruises.value.push(key.value);
      });
      if(selectedCruises.value.length > 1 && selectedCruises.value.length <=5) compareButtonEnabled.value = true; //max 5 cruises for comparison
      if(selectedCruises.value.length > 0) deleteButtonEnabled.value = true;
      console.log('Compare Cruises:', selectedCruises.value);      
    };

    const compareCruises = () => {
      router.push({ name: 'CompareCruises', query: {id: selectedCruises.value}});
    };

    const deleteCruises = () => {
      selectedCruises.value.forEach((key, index) => {
        console.log('Deleting Cruise '+key+' from leaflet.');
        if(user && !user.isAnonymous) {
          //For logged in users we fetch the wishlist/leaflet from the Seereisen-API
          deleteFromWishlist(AccessToken, key);
        } else {
          removeCruiseFromLeaflet(key); //session storage
        }
        document.getElementById('cruise_'+key).remove();
      });
    };

    const toggleCheckAll = (x, event) => {
      if(event.target.checked) {
        document.getElementsByName('compare-cruise').forEach((key, index) => { key.checked = true });
      } else {
        document.getElementsByName('compare-cruise').forEach((key, index) => { key.checked = false });
      }
      updateSelectedCruises();
    }

    const handleLeafletError = (err) => {
      error.value = err;
    };

    return { 
      JWTToken, error, wishlist, cruiseSummaryUnavailable, 
      compareCruises, compareButtonEnabled, updateSelectedCruises, toggleCheckAll,
      deleteCruises, deleteButtonEnabled,
      cruisesRemoved, handleLeafletError 
      };
  }
}
</script>

<style>

</style>