<template>
  <div v-if="cruiseDetails.routeName" class="cruise-summary" :id="'cruise_'+uniqueTravelId">
    <div class="backdrop" v-if="showBackdrop"></div>
    <div class="">
      <div class="d-flex justify-content-between align-items-stretch bg-light border">
        <div class="w-100">
          <template v-if="uniqueTravelId.substring(0,1)=='B'">
            <h4><span class="badge bg-danger" style="transform: rotate(-5deg);left:-10px;position:relative"><i class="fa fa-crown"></i> TOP-DEAL</span></h4>
          </template>
          <div class="p-2">
            <div class="cruisedetails-routename">{{cruiseDetails.routeName}}</div>
            <div class="cruisedetails-shipandcompany">{{cruiseDetails.shipName}} - {{cruiseDetails.shippingLineName}}</div>
            
            <div class="cruisedetails-tags">
              <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{region.regionName}}</span>&nbsp;
              <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{cruiseDetails.shipName}}</span>&nbsp;
              <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{cruiseDetails.shippingLineName.replace(' ','')}}</span>
            </div>
            <hr>
            <div class="cruisedetails-date mt-2">
              Reisedatum: {{cruiseDetails.routeScheduleStartFormatted}} - {{cruiseDetails.routeScheduleEndFormatted}} ({{cruiseDetails.routeScheduleDuration}} Nächte)
            </div>

                          
            
            <div v-if="showRoute" class="cruisedetails-route">
              <template v-if="routeToggleable">
              <div class="mt-2">
                <span class="link link-primary" @click="toggleRoute"><i class="fas fa-route text-primary"></i> Routenverlauf anzeigen</span>
              </div>          
              </template>
              <template v-if="!routeCollapsed">
                <h5>Reise-Route</h5>
                <RouteDetails :uniqueTravelId="uniqueTravelId" />
              </template>
            </div>    

            <div v-if="showRouteDetailsButton" class="mt-3 d-flex justify-content-end">
              <button @click.prevent="showCruiseDetails(cruiseDetails, uniqueTravelId)" class="btn btn-primary">Reisedetails</button>
            </div>        
          </div>
        </div>
        <div>
          <div class="cruise-titleimage position-relative overflow-hidden p-2 rounded">
            <div class="blurred" :style="{'background-image': 'url(https://media.xmlteam.de/files/aida-cruises/aidabella/schiff/4.jpeg)'}" style="background-position:center center;background-repeat:no-repeat;background-size:cover;filter:blur(10px);opacity:.75;position:absolute;top:0;left:0;right:0;bottom:0;display:block"></div>
            <img class="titleimage border mx-auto d-block position-relative" :src="'https://media.xmlteam.de/files/aida-cruises/aidabella/schiff/4.jpeg'" :alt="cruiseDetails.shipName+' - '+cruiseDetails.routeName" :title="cruiseDetails.shipName+' - '+cruiseDetails.routeName">
          </div>
        </div>
        <div v-if="selectableForCompare" class="align-self-center p-3">
          <input type="checkbox" class="form-check" name="compare-cruise" :value="uniqueTravelId" :data-wishid="wishId" @change="updateSelectedCruises()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import helperFunctions from '../../composables/helperFunctions';
//Components
import RouteDetails from '../CruiseDetails/RouteDetails.vue';
import { inject, onMounted } from '@vue/runtime-core';
import useBookingAPI from '../../composables/useBookingAPI';
import useMetaAPI from '../../composables/useMetaAPI';
import { useRouter } from 'vue-router';

export default {
  props: ['uniqueTravelId', 'wishId', 'showRouteDetailsButton', 'showRoute', 'routeToggleable', 'routeCollapsed', 'selectableForCompare'],
  components: { RouteDetails },
  setup(props, context) {
    const lang = inject('lang');
    const JWTToken = ref(inject('JWTToken'));
    const error = ref('');
    const router = useRouter();

    const { convertYMDHISToDate, generateSlug } = helperFunctions();
    const { BookingApiError, getCruiseDetails } = useBookingAPI();
    const { MetaApiError, getRegions } = useMetaAPI();

    const showBackdrop = ref(false);
    const uniqueTravelId = ref(props.uniqueTravelId);
    const wishId = ref(props.wishId);
    const showRouteDetailsButton = ref(props.showRouteDetailsButton);
    const showRoute = ref(props.showRoute);
    const routeToggleable = ref(props.routeToggleable);
    const routeCollapsed = ref(props.routeCollapsed);
    const selectableForCompare = ref(props.selectableForCompare);

    const cruiseDetails = ref({});
    const cruiseTitleimageSrc = ref('');

    onMounted(() => {
      fetchCruiseDetails();
    });

    const blockContent = (status) => {
      showBackdrop.value = status;
    }   

    const toggleRoute = () => {
      routeCollapsed.value = !routeCollapsed.value;
    } 

    const showCruiseDetails = (cruise, uniqueTravelId) => {
      var slug = generateSlug(cruise.routeName)
      console.log('Navigating to CruiseDetails:', {uniqueTravelId: uniqueTravelId, slug: slug });
      router.push({ name: 'CruiseDetails', params: {uniqueTravelId: uniqueTravelId, slug: slug }});
    };

    const updateSelectedCruises = () => {
      context.emit('updateSelectedCruises');
    };

    function fetchCruiseDetails() {
      blockContent(true);

      if(JWTToken.value !== null && typeof JWTToken.value !== 'undefined') {
        getCruiseDetails(uniqueTravelId.value, JWTToken.value)
          .then((result) => {
            if(!result) {
              //Cruise coundn't be fetched
              blockContent(false);
              if(BookingApiError.value.code==200 && BookingApiError.value.error=='no_data_available') emitSummaryUnavailable(uniqueTravelId.value); //Delete cruise from leaflet
              error.value += BookingApiError.value.userMessage;
              context.emit('error', error.value);
            } else {
              //Fetched Results from Booking-API
              cruiseDetails.value = result.cruiseDetails; 

              //General cruise details
              cruiseDetails.value.destinations = [];
              getRegions("", JWTToken.value)
                .then((regionsResult) => {
                  Object.keys(regionsResult.regions).forEach((key) => {
                    if(cruiseDetails.value.routeRegions.includes(regionsResult.regions[key].regionId.toString())) cruiseDetails.value.destinations.push(regionsResult.regions[key]) //Pushes all route destinations with RouteId and RouteName to array
                  })
                })
                .catch((err) => { console.log(err), error.value += MetaApiError });

              //Formatted Cruise Start and End Dates
              let dateFormatOptions = {weekday:'short', year: 'numeric', month: 'numeric', day: 'numeric'};
              cruiseDetails.value.routeScheduleStartFormatted = convertYMDHISToDate(cruiseDetails.value.routeScheduleStart).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);
              cruiseDetails.value.routeScheduleEndFormatted = convertYMDHISToDate(cruiseDetails.value.routeScheduleEnd).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);

              //Cruise Title Image
              cruiseTitleimageSrc.value = 'https://media.xmlteam.de/files/aida-cruises/aidabella/schiff/4.jpeg';
              
              blockContent(false);

            }            
          })
          .catch((err) => { 
            console.log(err), error.value += BookingApiError.value.message;
            context.emit('error', error.value);
          });
      }
    }

    const emitSummaryUnavailable = (uniqueTravelId) => {
      console.log('summaryUnavailable',uniqueTravelId);
      context.emit('summaryUnavailable', uniqueTravelId);
    };

    return { 
      error, showBackdrop,
      uniqueTravelId, wishId, 
      cruiseDetails, cruiseTitleimageSrc, 
      showRouteDetailsButton, showCruiseDetails,
      showRoute, routeToggleable, routeCollapsed, toggleRoute,
      selectableForCompare, updateSelectedCruises
    };
  }
}
</script>

<style scoped>
.cruisedetails-routename {
  font-size: 30px;
  font-weight: 600;
}
.cruisedetails-shipandcompany {
  font-size: 24px;
}
.cruisedetails-date {
  font-size: 20px;
}
</style>