<template>
    <div class="container">        
        <div class="ccontainer">
          <WelcomeScreen />
        </div>        
    </div>
</template>

<script>
//Modules

//Components
import WelcomeScreen from '../components/Home/WelcomeScreen.vue'
//Composables

export default {
    components: { WelcomeScreen },
    setup() {
      
  

      return {  }

    },

    
}
</script>

<style scoped>

</style>