<template>
  <div class="cabin-summary">
    <div class="d-flex justify-content-between">
      <div class="w-100 bg-light border">
        <div class="p-2">
          <div class="cabinsummary-cabintype">
            {{(cabinType==1) ? 'Innenkabine': (cabinType==2) ? 'Außenkabine': (cabinType==3) ? 'Balkonkabine': (cabinType==4) ? 'Suite':'n.V.'}}
            - {{cabin}}
          </div>
          <hr>
          <div class="cabinsummary-pmc">
            {{pmc}}
          </div>
          <hr>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla officiis ipsum commodi harum animi minus eos molestias suscipit in esse quasi veniam beatae, iusto doloribus est aperiam tempora possimus natus dicta id molestiae quae doloremque? Nihil quo animi distinctio eos provident, accusamus voluptas incidunt delectus modi molestiae soluta atque maiores!</p>
        </div>
      </div>
      <div>
        <div class="cabin-image position-relative overflow-hidden p-2 rounded">
          <div class="blurred" :style="{'background-image': 'url(https://media.xmlteam.de/t/m/s/aida-cruises/aidabella/kabinen/balkon.jpeg)'}" style="background-position:center center;background-repeat:no-repeat;background-size:cover;filter:blur(10px);opacity:.75;position:absolute;top:0;left:0;right:0;bottom:0;display:block"></div>
          <img class="titleimage border mx-auto d-block position-relative" :src="'https://media.xmlteam.de/t/m/s/aida-cruises/aidabella/kabinen/balkon.jpeg'" :alt="'Kabinentyp: '+cabin" :title="'Kabinentyp: '+cabin">
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
  props: ['cabinType', 'cabin', 'pmc'],
  setup(props) {
    const cabinType = ref(props.cabinType);
    const cabin = ref(props.cabin);
    const pmc = ref(props.pmc);

    return { cabinType, cabin, pmc };
  }
}
</script>

<style>

</style>