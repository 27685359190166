<template>
  <div v-if="booking.booking_id" class="booking-summary" :id="booking.booking_id">
    <div class="backdrop" v-if="showBackdrop"></div>
    <div class="">
      <div class="d-flex justify-content-between align-items-stretch bg-light border">
        <div class="p-3">
          <div class="row">
            <div class="col col-9">
              <h3>{{booking.cc_tour_name}}</h3>
              <h5>Buchungsnummer: {{booking.booking_id}}</h5>
              <h5>CC Buchungsnummer: {{booking.cc_booking_id}}</h5>    
              <h6>TCC ID: {{booking.unique_tcc_travel_id}}</h6>          
            </div>
            <div class="col col-3">
              <button class="w-100 btn btn-success"><i class="fa fa-headset"></i> Kundenberater</button>
            </div>
          </div>

          <template v-if="booking.unique_tcc_travel_id">
            <CruiseSummary class="mb-3"
              :uniqueTravelId="booking.unique_tcc_travel_id" 
              :showRouteDetailsButton="true" 
              :showRoute="true" 
              :routeToggleable="true" 
              :routeCollapsed="true" 
              :selectableForCompare="false"
              />
          </template>             
          <hr>

          <div class="d-flex justify-content-between mb-3 gap-3">
            <div class="card w-100">
              <div class="card-body">
                <div class="card-title">
                  <h5>Ihre Reise</h5>
                </div>
                <div class="card-text">
                  Gebucht am: {{booking.created}}<br><br>
                  Kabinen-Nummer: {{booking.cabinno}}<br>
                  Kabinentyp: {{(typeof lang.cabinTypes[booking.cabin_type] !== 'undefined') ? lang.cabinTypes[booking.cabin_type] : booking.cabin_type}} ({{booking.cabin}})<br>
                  Preismodell: {{booking.pmc}}<br><br>
                  Inklusive Flug: {{(booking.has_flight == 1) ? 'Ja' : 'Nein'}}<br>
                  Start-Flughafen: {{booking.start_airport}}<br><br>
                  <b>Gesamtpreis: {{booking.total_price}}</b>
                </div>
              </div>
            </div>

            <div class="card w-100">
              <div class="card-body">
                <div class="card-title">
                  <h5>Ihre Kontaktdaten</h5>
                </div>
                <div class="card-text">
                  <div v-for="(value, index) of Object.entries(booking)" :key="index">
                    <template v-if="value[0].indexOf('registrant') > -1">
                      {{(typeof lang.ccBookingFields[value[0]] !== 'undefined') ? lang.ccBookingFields[value[0]] : [value[0]]}}: {{value[1]}}
                    </template>
                  </div>  
                </div>
              </div>
            </div>   

            <div class="card w-100">
              <div class="card-body">
                <div class="card-title">
                  <h5>Reisende Personen: {{booking.traveler_count}}</h5>
                </div>
                <div class="card-text">
                  <div v-for="(value, index) of Object.entries(booking)" :key="index">
                    <template v-if="value[0].indexOf('traveler') > -1">
                      {{(typeof lang.ccBookingFields[value[0]] !== 'undefined') ? lang.ccBookingFields[value[0]] : [value[0]]}}: {{value[1]}}
                    </template>
                  </div> 
                </div>
              </div>
            </div>  
          </div> 

          <div class="d-flex justify-content-between mb-3  gap-3">
            <div class="card w-25">
              <div class="card-body">
                <div class="card-title">
                  <h5>Reise-Veranstalter</h5>
                </div>
                <div class="card-text">
                  <div v-for="(value, index) of Object.entries(booking)" :key="index">
                    <template v-if="value[0].indexOf('cc_operator') > -1 && value[1] !== null && value[1].indexOf('pdf') == -1">
                      {{(typeof lang.ccBookingFields[value[0]] !== 'undefined') ? lang.ccBookingFields[value[0]] : [value[0]]}}: {{value[1]}}
                    </template>
                  </div>  
                </div>
              </div>
            </div>              

            <div class="card w-75">
              <div class="card-body">
                <div class="card-title">
                  <h5>Reiseunterlagen</h5>
                </div>
                <div class="card-text">
                  <div v-for="(value, index) of Object.entries(booking)" :key="index">
                    <template v-if="value[0].indexOf('cc_operator') > -1 && value[1] !== null && value[1].indexOf('pdf') > -1">
                      {{(typeof lang.ccBookingFields[value[0]] !== 'undefined') ? lang.ccBookingFields[value[0]] : [value[0]]}}: <a :href="value[1]" target="_blank">{{value[1]}}</a>
                    </template>
                  </div>
                </div>
              </div>
            </div>              
          </div>

          <div>
            <span v-for="(value, index) of Object.entries(booking)" :key="index" style="font-size:9px">
              {{value[0]}} : {{value[1]}}
            </span>
          </div>       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import helperFunctions from '../../composables/helperFunctions';
//Components
import { inject, onBeforeMount, onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import CruiseSummary from './CruiseSummary.vue';

export default {
  props: ['booking'],
  components: {CruiseSummary},
  setup(props, context) {
    const lang = inject('lang');
    const JWTToken = ref(inject('JWTToken'));
    const error = ref('');
    const router = useRouter();

    const showBackdrop = ref(false);

    const booking = ref(props.booking);

    const { generateSlug } = helperFunctions();  

    onMounted(() => {
      Object.entries(booking.value).forEach(([key, value]) => {
        if(typeof booking.value[key] == 'string') booking.value[key] = booking.value[key].replaceAll('undefined', '');
        if(key.indexOf('traveler') > -1 && booking.value[key] === null) {
          //delete empty traveler fields
          delete booking.value[key];
        }
      })
    })

    const showCruiseDetails = (routeName, uniqueTravelId) => {
      var slug = generateSlug(routeName)
      console.log('Navigating to CruiseDetails:', {uniqueTravelId: uniqueTravelId /*, slug: slug */ });
      router.push({ name: 'CruiseDetails', params: {uniqueTravelId: uniqueTravelId /*, slug: slug */ }});
    };

    return { lang, booking, showBackdrop, showCruiseDetails }
  }
}
</script>

<style>

</style>