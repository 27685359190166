<template>
  <div class="compare-header-cruise border p-2">
    <div>
      <a href=""><img class="w-100" :src="cruise.titleimageSrc" :alt="cruise.routeName"></a>
    </div>
    <div>
      <a href=""><h4>{{cruise.routeName}}</h4></a>
    </div>
    <div>
      {{cruise.shipName}} - {{cruise.shippingLineName}}
    </div>
    <div>
      {{cruise.routeScheduleDuration}} Nächte<br>
      Von: {{cruise.routeScheduleStartFormatted}}<br>
      Bis: {{cruise.routeScheduleEndFormatted}}
    </div>
    <div>
      Region(en): 
                
                <span v-for="region in cruise.destinations" :key="region.regionName">{{region.regionName}} </span>
                
    </div>
    <div>799,-<br>899,- mit Flug</div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
  props: ['cruise'],
  setup(props) {

    const cruise = ref(null);

    if(typeof props.cruise !== 'undefined') {
      cruise.value = props.cruise;
    }

    return { cruise }
  }
}
</script>

<style>

</style>